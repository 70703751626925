import React, { useState, useEffect, useRef } from "react";
import { useOrders } from "./providers/OrdersContext";
import "./OrderHistoryStyle.scss";
import OrderHistoryStep from "./components/OrderHistoryStep";

export default function OrderHistory() {
	const { order, orderProcess } = useOrders();
	const [historyStepList, setHistoryStepList] = useState([]);
	const [finishedStepList, setFinishedStepList] = useState(false);
	const [progressBarSize, setProgressBarSize] = useState(0);
	const [mainProgressBar, setMainProgressBar] = useState({
		completedBar: { size: 0 },
		otherStatusBar: {
			size: 0,
			status: "",
		},
	});
	const [secondaryProgressBar, setSecondaryProgressBar] = useState({
		completedBar: { size: 0 },
		otherStatusBar: {
			size: 0,
			status: "",
		},
	});
	const temporaryIntegrationList = useRef([]);

	useEffect(() => {
		historyStepMapper(orderProcess);
	}, [orderProcess]);

	useEffect(() => {
		if (finishedStepList) {
			handleProgressBar(historyStepList);
			return;
		}
		setFinishedStepList(true);
	}, [historyStepList]);

	const historyStepMapper = (orderProcess) => {
		let divergentStatus = ["ReturningGoods", "WaitingCancel", "Canceled"];
		let actionList = order?.Actions;

		orderProcess.Steps.sort((a, b) => {
			if (a.OrderStatus === "Finalized") return 1;
			if (b.OrderStatus === "Finalized") return -1;

			if (a.Position === -1) return 1;
			if (b.Position === -1) return -1;

			return a.Position - b.Position;
		});

		orderProcess.Steps = orderProcess.Steps.filter((step) => {
			if (actionList?.some((step) => step.OrderStatus === "WaitingCancel")) {
				return (
					step?.OrderStatus === "Canceled" ||
					actionList?.some((stp) => stp?.OrderStatus === step?.OrderStatus)
				);
			}
			return (
				(step.OrderStatus === "ReturningGoods" &&
					actionList?.some((stp) => stp?.OrderStatus === "ReturningGoods")) ||
				!divergentStatus.includes(step?.OrderStatus)
			);
		});

		orderProcess.Steps.forEach((orderStep) => {
			const stepStatusObj = handleStepStatusObj(orderStep);
			if (orderStep?.IntegrationTree?.length) {
				recursivelyMapIntegrations(
					orderStep?.IntegrationTree,
					orderStep.OrderStatus
				);
				reorganizeIntegrations();
			}

			const newStepObj = {
				componentType: "OrderType",
				OrderStatus: orderStep.OrderStatus,
				mainIcon: handleStepCardIcon(orderStep),

				issuer: null,
				urlLog: null,
				date: handleFormatDate(stepStatusObj),
				status: handleStatus(stepStatusObj),
				StepIntegrations: stepStatusObj?.StepIntegrations,
			};
			setHistoryStepList((oldStepList) => [...oldStepList, newStepObj]);
		});
	};

	const recursivelyMapIntegrations = (IntegrationTree, OrderStatus) => {
		IntegrationTree.forEach((integrationStep) => {
			const stepStatusObj = handleStepStatusObj(integrationStep, {
				isIntegration: true,
			});

			const newStepObj = {
				componentType: "IntegrationType",
				OperationId: integrationStep.OperationId,
				IntegrationId: integrationStep.IntegrationId,
				mainIcon: handleStepCardIcon(integrationStep),
				cancelIntegration:
					(OrderStatus === "WaitingCancel" || OrderStatus === "Canceled") &&
					stepStatusObj?.IsCompletedSuccessfully,

				issuer: stepStatusObj?.Origin || null,
				urlLog: null,
				date: handleFormatDate(stepStatusObj),
				status: handleStatus(stepStatusObj),
			};
			temporaryIntegrationList.current.push(newStepObj);
		});
	};

	const reorganizeIntegrations = () => {
		let statusOrder = {
			completed: 1,
			processing: 2,
			errorWarning: 3,
			pending: 4,
		};
		temporaryIntegrationList.current.sort(
			(a, b) => statusOrder[a.status] - statusOrder[b.status]
		);
		temporaryIntegrationList.current.forEach((integration) => {
			setHistoryStepList((oldStepList) => [...oldStepList, integration]);
		});
		temporaryIntegrationList.current = [];
	};

	const handleStepStatusObj = (completeStep, isIntegration = false) => {
		let stepId = completeStep?.OrderStatus || completeStep?.OperationId;
		let statusObj =
			order?.Actions?.find((step) => step.OrderStatus === stepId) ||
			order?.Actions?.find((step) =>
				step.StepIntegrations.some(
					(integration) => integration?.OperationId === stepId
				)
			);

		if (isIntegration) {
			statusObj = statusObj?.StepIntegrations?.find(
				(integration) => integration?.OperationId === stepId
			);
		}

		return statusObj;
	};

	const handleStepCardIcon = (step) => {
		let stepId = step?.OrderStatus || step?.OperationId;
		if (stepId === "WaitingReturnGoods" || stepId === "WaitingCancel")
			return "access_time";
		if (
			step.hasOwnProperty("StepRequiresManualAction") &&
			step?.StepRequiresManualAction
		) {
			return "person_outline";
		} else return "device_hub";
	};

	const handleProgressBar = (completeStepList) => {
		let fullSize;
		completeStepList[0]?.status !== "pending"
			? (fullSize = -75)
			: (fullSize = -52 - 20);

		let mainCompletedSize = 0;
		let mainOtherStatusSize = 0;
		let mainOtherStatus = "";

		let secondaryCompletedSize = 0;
		let secondaryOtherSize = 0;
		let secondaryOtherStatus = "";
		completeStepList.forEach((step) => {
			if (step?.status !== "pending") {
				fullSize += 75 + 20;
				if (step?.hasOwnProperty("OrderStatus")) {
					if (step?.OrderStatus === "Created") mainCompletedSize += 11.5;
					else if (step?.status === "completed") {
						mainCompletedSize += 75 + 20;
						step?.StepIntegrations?.length > 0 &&
							step?.StepIntegrations.every(
								(integration) => integration?.IsCompletedSuccessfully
							) &&
							step.StepIntegrations.forEach((innerStepIntegration) => {
								mainCompletedSize += 75 + 20;
							});
					} else if (
						step?.OrderStatus === "WaitingCancel" ||
						step?.OrderStatus === "Canceled"
					) {
						if (step?.StepIntegrations?.length === 0) {
							step?.status === "canceled"
								? (secondaryCompletedSize += 75 + 20)
								: (secondaryOtherSize += 75 + 20) &&
								  (secondaryOtherStatus = step?.status);
						} else {
							// All integrations completed
							if (
								step?.StepIntegrations.every(
									(integration) => integration?.IsCompletedSuccessfully
								)
							) {
								secondaryCompletedSize += 75 + 20;
								step.StepIntegrations.forEach((innerStepIntegration) => {
									secondaryCompletedSize += 75 + 20;
								});
							} else {
								// Not all integrations completed
								secondaryOtherSize += 75 + 20;
								secondaryOtherStatus = step?.status;
								step.StepIntegrations.forEach((innerStepIntegration) => {
									if (innerStepIntegration?.IsCompletedSuccessfully) {
										secondaryCompletedSize += 75 + 20;
									} else {
										!innerStepIntegration?.IsProcessedSuccessfully &&
										!innerStepIntegration?.LastRanWithError
											? (secondaryOtherSize += 52 + 20)
											: (secondaryOtherSize += 75 + 20);
									}
								});
							}
						}
					} else {
						mainOtherStatusSize += 75 + 20;
						mainOtherStatus = step?.status;
						step?.StepIntegrations?.length > 0 &&
							step.StepIntegrations.forEach((innerStepIntegration) => {
								if (innerStepIntegration?.IsCompletedSuccessfully) {
									mainCompletedSize += 75 + 20;
								} else {
									!innerStepIntegration?.IsProcessedSuccessfully &&
									!innerStepIntegration?.LastRanWithError
										? (mainOtherStatusSize += 52 + 20)
										: (mainOtherStatusSize += 75 + 20);
								}
							});
					}
				}
			} else {
				fullSize += 52 + 20;
			}
		});

		setProgressBarSize(fullSize);
		setMainProgressBar((prevState) => ({
			...prevState,
			completedBar: { size: mainCompletedSize },
			otherStatusBar: {
				size: mainOtherStatusSize,
				status: mainOtherStatus,
			},
		}));
		setSecondaryProgressBar((prevState) => ({
			...prevState,
			completedBar: { size: secondaryCompletedSize },
			otherStatusBar: {
				size: secondaryOtherSize,
				status: secondaryOtherStatus,
			},
		}));
	};

	const handleFormatDate = (stepStatusObj) => {
		if (!stepStatusObj) return "";
		return new Date(stepStatusObj?.LastUpdate).toLocaleString("pt-br");
	};

	const handleStatus = (stepStatusObj) => {
		if (!stepStatusObj) return "pending";

		//Integration steps
		if (stepStatusObj?.hasOwnProperty("OperationId")) {
			if (stepStatusObj?.LastRanWithError) {
				return order?.Actions?.some(
					(step) => step.OrderStatus === "WaitingCancel"
				) || order?.ProcessingState?.IsProcessing === false
					? "errorWarning"
					: "retry";
			}
			if (stepStatusObj?.IsCompletedSuccessfully) return "completed";

			if (
				stepStatusObj?.IsProcessedSuccessfully === true &&
				stepStatusObj?.IsCompletedSuccessfully === false
			) {
				return "processing";
			}
			return "pending";
		}

		// Regular order steps
		let OrderStepId = stepStatusObj?.OrderStatus;
		let integrationError = stepStatusObj?.StepIntegrations?.some(
			(integration) => integration?.LastRanWithError
		);

		if (
			OrderStepId === order?.ProcessingState?.LastProcessedStatus &&
			!order?.ProcessingState?.IsFinished &&
			!integrationError
		)
			return "pending";

		if (stepStatusObj?.IsSuccessfull) {
			return OrderStepId === "Canceled" || OrderStepId === "WaitingCancel"
				? "canceled"
				: "completed";
		}
		if (
			OrderStepId !== "Canceled" &&
			OrderStepId !== "WaitingCancel" &&
			order?.Actions?.some((step) => step.OrderStatus === "WaitingCancel")
		) {
			return "canceled";
		}
		if (integrationError) {
			return order?.ProcessingState?.IsProcessing ? "retry" : "errorWarning";
		}
		if (
			OrderStepId === order?.ProcessingState?.LastProcessedStatus &&
			order?.ProcessingState?.IsProcessing
		) {
			return "processing";
		}
		if (stepStatusObj.IsSuccessfull === false && integrationError) {
			return "processing";
		}

		return "pending";
	};

	return (
		<div className="card pt-3 mb-3">
			<div className="card-header px-4 pt-4 pb-2">
				<h4 className="card-title">
					Histórico do pedido nº {order?.FriendlyCode}
				</h4>
			</div>
			<div className="card-body history-card-body mx-3">
				<div className="order-history d-flex justify-content-center mx-auto">
					<div
						className="progress progress-bar-vertical align-self-center d-flex flex-column justify-content-start"
						style={{ height: `${progressBarSize}px` }}
					>
						<div
							className="progress-bar completed"
							role="progressbar"
							aria-valuenow={mainProgressBar?.completedBar?.size}
							aria-valuemin="0"
							aria-valuemax={progressBarSize}
							style={{ height: `${mainProgressBar?.completedBar?.size}px` }}
						></div>
						{mainProgressBar?.otherStatusBar?.status && (
							<div
								className={`progress-bar ${mainProgressBar?.otherStatusBar.status}`}
								role="progressbar"
								aria-valuenow={mainProgressBar?.otherStatusBar.size}
								aria-valuemin="0"
								aria-valuemax={progressBarSize}
								style={{ height: `${mainProgressBar?.otherStatusBar.size}px` }}
							></div>
						)}
						{secondaryProgressBar?.completedBar?.size && (
							<div
								className="progress-bar canceled"
								role="progressbar"
								aria-valuenow={secondaryProgressBar?.completedBar?.size}
								aria-valuemin="0"
								aria-valuemax={progressBarSize}
								style={{
									height: `${secondaryProgressBar?.completedBar?.size}px`,
								}}
							></div>
						)}
						{secondaryProgressBar?.otherStatusBar?.status && (
							<div
								className={`progress-bar ${secondaryProgressBar?.otherStatusBar.status}`}
								role="progressbar"
								aria-valuenow={secondaryProgressBar?.otherStatusBar.size}
								aria-valuemin="0"
								aria-valuemax={progressBarSize}
								style={{
									height: `${secondaryProgressBar?.otherStatusBar.size}px`,
								}}
							></div>
						)}
					</div>
					<div className="w-100">
						{historyStepList.map((historyStepObj, index) => (
							<OrderHistoryStep
								key={index}
								historyStepObj={historyStepObj}
								IsDelivery={order?.IsDelivery}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

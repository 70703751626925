import { LegacyActionLogDto } from "../../dtos/legacyCommerceManagement/LegacyActionLogDto";
import { LegacyCustomDataDto } from "../../dtos/legacyCommerceManagement/LegacyCustomDataDto";
import { LegacyOrderDto } from "../../dtos/legacyCommerceManagement/LegacyOrderDto";
import { ActionLogModel } from "../../models/commerce/ActionLogModel";
import { CustomDataModel } from "../../models/commerce/CustomDataModel";
import { OrderModel } from "../../models/commerce/OrderModel";

const legacyToOrderModel = (order: LegacyOrderDto): OrderModel => {
	const customData: CustomDataModel[] = order.customData.map(
		(data: LegacyCustomDataDto): CustomDataModel => ({
			key: data.key,
			value: data.value,
		})
	);

	const actionLogs: ActionLogModel[] = order.actionLogs.map(
		(actionLog: LegacyActionLogDto): ActionLogModel => ({
			title: actionLog.title,
			displayMessage: actionLog.displayMessage,
		})
	);

	const genericOrder: OrderModel = {
		actionLogs: actionLogs,
		id: order.id,
		isDelivery: order.isDelivery,
		buyer: {
			fullName: order.buyer.fullName,
		},
		customData: customData,
		seller: {
			fullName: order.seller.fullName,
			address: {
				city: order.seller.address?.city ?? "",
				complement: order.seller.address?.complement ?? "",
				neighborhood: order.seller.address?.neighborhood ?? "",
				number: order.seller.address?.number ?? "",
				state: order.seller.address?.state ?? "",
				street: order.seller.address?.street ?? "",
				zipCode: order.seller.address?.cep ?? "",
			},
		},
		status: order.status,
		shippingData: {
			deliveryAddress: order.isDelivery
				? {
						city: order.deliveryAddress.city ?? "",
						neighborhood: order.deliveryAddress.neighborhood ?? "",
						number: order.deliveryAddress.number ?? "",
						state: order.deliveryAddress.state ?? "",
						street: order.deliveryAddress.street ?? "",
						zipCode: order.deliveryAddress.cep ?? "",
						complement: order.deliveryAddress.complement ?? "",
				  }
				: null,
		},
	};

	return genericOrder;
};

export const OrderMapper = { legacyToOrderModel };

import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { CpOrdersDto } from "../../domain/dtos/cpCommerceManagement/CpOrdersDto";
import { AddresseeUpdateModel } from "../../domain/models/commerce/AddresseeUpdateModel";
import { CustomDataModel } from "../../domain/models/commerce/CustomDataModel";
import { ActionLogModel } from "../../domain/models/commerce/ActionLogModel";

interface ICPCommerceManagement {
	getOrders: (params: string) => Promise<CpOrdersDto>;
	updateAddressee: (
		orderId: string,
		data: AddresseeUpdateModel
	) => Promise<boolean>;
	upsertCustomData: (
		orderId: string,
		data: CustomDataModel[]
	) => Promise<boolean>;
	createActionLog: (orderId: string, data: ActionLogModel) => Promise<boolean>;
}

class CPCommerceManagement extends BaseApi implements ICPCommerceManagement {
	async getOrders(params: string): Promise<CpOrdersDto> {
		let resp = await this.request<CpOrdersDto>(
			`orders?${params}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get orders");
		}

		return resp.data;
	}

	async updateAddressee(
		orderId: string,
		data: AddresseeUpdateModel
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/deliveryAddress`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async upsertCustomData(
		orderId: string,
		data: CustomDataModel[]
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/customData`,
			HttpMethod.Post,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async createActionLog(
		orderId: string,
		data: ActionLogModel
	): Promise<boolean> {
		const resp = await this.request(
			`orders/${orderId}/actionLog`,
			HttpMethod.Post,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}
}

export const cpCommerceManagementApi: ICPCommerceManagement =
	new CPCommerceManagement("cp/management/commerce/");

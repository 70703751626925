import NewToastComponent from "../../../../components/NewToastComponent";
import { useState, ChangeEvent, useEffect } from "react";
import { retailerManagementApi } from "../../../../infrastructure/api/RetailerManagementApi";
import { normalizeDigitsOnly } from "../../../../infrastructure/utils/StringUtils";
import { AddressView } from "../../../../domain/views/stores/AddressView";
import { AddressDto } from "../../../../domain/dtos/stores/AddressDto";
import { useHistory } from "react-router-dom";
import { AutocompleteStateView } from "../../../../domain/views/legacyApi/AutocompleteStateView";
import { i18n } from "../../../../translate/i18n";
import { ZipCodeDto } from "../../../../domain/dtos/SalesApi/ZipCodeDto";
import {
	FormErrors,
	FormValidations,
	FormValidator,
	InputValidator,
} from "../../../../infrastructure/utils/FormUtils";
import { AddressInputs } from "../../enums/StoreInputs";

export default function useAddressService(
	data: AddressView,
	getSeller: (sellerId: string) => Promise<void>
) {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentState, setCurrentState] =
		useState<AutocompleteStateView | null>(null);
	const [isLoadingZipCode, setIsLoadingZipCode] = useState<boolean>(false);
	const [address, setAddress] = useState<AddressView>({
		street: null,
		number: null,
		complement: null,
		neighborhood: null,
		cep: null,
		city: null,
		state: null,
		country: null,
		fullAddress: null,

		isPrimary: false,
		isDeleted: false,
	});
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const formValidationMapper: FormValidations = {
		[AddressInputs.Cep]: InputValidator.isZipCodeValid,
		[AddressInputs.City]: InputValidator.isRequired,
		[AddressInputs.Neighborhood]: InputValidator.isRequired,
		[AddressInputs.Number]: InputValidator.isRequired,
		[AddressInputs.State]: InputValidator.isRequired,
		[AddressInputs.Street]: InputValidator.isRequired,
	};

	useEffect(() => {
		getSellerAddress(data);
	}, []);

	const getSellerAddress = (data: AddressView): void => {
		if (!data) return;

		try {
			setAddress(data);
		} catch (error) {
			NewToastComponent({
				status: "error",
				title: i18n.t("stores.Address.LoadError"),
				message: i18n.t("stores.Address.LoadErrorMessage"),
			});

			setTimeout(() => {
				history.push(`/stores/1`);
			}, 3000);
		}
	};

	const handleZipCodeChange = (
		value: string,
		zipCodeResponse?: ZipCodeDto | false
	) => {
		const zipCodeErrors: string[] = formValidationMapper[AddressInputs.Cep](
			value,
			zipCodeResponse
		);
		setFormErrors((prevState) => ({
			...prevState,
			[AddressInputs.Cep]: zipCodeErrors,
		}));
		setAddress((prevState) => ({
			...prevState,
			cep: value,
			...(zipCodeResponse && {
				city: zipCodeResponse.city,
				complement: "",
				neighborhood: zipCodeResponse.neighborhood,
				number: "",
				state: zipCodeResponse.state,
				street: zipCodeResponse.street,
			}),
		}));
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (formValidationMapper[name] !== undefined) {
			const errors: string[] = formValidationMapper[name](value);
			setFormErrors((prevState) => ({ ...prevState, [name]: errors }));
		}
		setAddress((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleAutocompleteChange = (
		state: AutocompleteStateView | null
	): void => {
		setCurrentState(state);
		const stateCode = state?.code ?? "";
		setAddress((prevState) => ({
			...prevState,
			[AddressInputs.State]: stateCode,
		}));
		const errMessages: string[] =
			formValidationMapper[AddressInputs.State](stateCode);
		setFormErrors((prevState) => ({
			...prevState,
			[AddressInputs.State]: errMessages,
		}));
	};

	const upsertAddress = async (sellerId: string, data: AddressView) => {
		let errors: FormErrors = {};
		for (const [key, value] of Object.entries(data)) {
			if (formValidationMapper[key] === undefined) continue;
			const errorMessages: string[] = formValidationMapper[key](value);
			errors[key] = errorMessages;
		}
		setFormErrors(errors);
		if (FormValidator.hasError(errors)) return;

		setIsLoading(true);
		const state = currentState?.code ?? "";
		let newData: AddressDto = {
			id: data.id,
			street: data.street,
			number: data.number,
			complement: data.complement,
			neighborhood: data.neighborhood,
			cep: data?.cep ? normalizeDigitsOnly(data?.cep) : null,
			city: data.city,
			state: state,
			country: data.country,
			fullAddress: data.fullAddress,

			isPrimary: data.isPrimary,
			isDeleted: data.isDeleted,
		};

		let success = await retailerManagementApi.upsertAddress(sellerId, newData);

		if (!success) {
			NewToastComponent({
				status: "error",
				title: i18n.t("stores.Address.UpsertError"),
				message: i18n.t("stores.Address.UpsertErrorMessage"),
			});
			setIsLoading(false);
			return;
		}
		setAddress(newData);
		NewToastComponent({
			status: "success",
			title: i18n.t("stores.Address.UpsertSuccess"),
		});
		getSeller(sellerId);
		setIsLoading(false);
	};

	return {
		// Props
		address,
		isLoading,
		currentState,
		formErrors,
		isLoadingZipCode,
		setIsLoadingZipCode,
		setCurrentState,
		handleAutocompleteChange,
		// Functions
		handleZipCodeChange,
		handleInputChange,
		upsertAddress,
	};
}

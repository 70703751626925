import BaseApi from "./BaseApi";
import { SellerClusterDto } from "../../domain/dtos/stores/SellerClusterDto";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { LayoutProfile } from "../../domain/schemas/layoutProfile/LayoutProfile";
import { SellerIntegrationDto } from "../../domain/dtos/stores/SellerIntegrationDto";
import { AddressDto } from "../../domain/dtos/stores/AddressDto";
import { SellerModalityDto } from "../../domain/dtos/stores/SellerModalityDto";
import { SellerDto } from "../../domain/dtos/stores/SellerDto";
import { SellerBasicInfoDto } from "../../domain/dtos/stores/SellerBasicInfoDto";
import { SellerWorkingHoursDto } from "../../domain/dtos/stores/SellerWorkingHoursDto";
import { SellerOrderProcessDto } from "../../domain/dtos/stores/SellerOrderProcessDto";
import { AutocompleteSellerDto } from "../../domain/dtos/dealers/AutocompleteSellerDto";
import { StoresDto } from "../../domain/dtos/stores/StoresDto";

interface IRetailerManagement {
	upsertAddress: (sellerId: string, data: AddressDto) => Promise<boolean>;

	getModalities: (sellerId: string) => Promise<SellerModalityDto[]>;
	updateModalities: (
		sellerId: string,
		data: SellerModalityDto[]
	) => Promise<boolean>;

	getWorkingHours: (sellerId: string) => Promise<SellerWorkingHoursDto[]>;
	upsertWorkingHours: (
		productId: string,
		data: SellerWorkingHoursDto[]
	) => Promise<boolean>;
	getSeller: (sellerId: string) => Promise<SellerDto>;
	createSeller: (data: SellerBasicInfoDto) => Promise<boolean>;
	updateSeller: (sellerId: string, data: SellerDto) => Promise<boolean>;
	getSellerClusters: () => Promise<SellerClusterDto[]>;

	getIntegrationConfig: (
		sellerId: string,
		integrationConfigId: string
	) => Promise<SellerIntegrationDto>;
	upsertIntegrationConfig: (
		sellerId: string,
		data: SellerIntegrationDto
	) => Promise<boolean>;

	getOrderProcesses: () => Promise<SellerOrderProcessDto[]>;
	upsertOrderProcess: (
		sellerId: string,
		data: SellerOrderProcessDto
	) => Promise<boolean>;

	getLayoutProfile: () => Promise<LayoutProfile>;
	getAutocompleteSellers: () => Promise<AutocompleteSellerDto[]>;

	getStoreList: (queryParams: string) => Promise<StoresDto>;
	deleteStore: (storeId: string) => Promise<boolean>;
}

class RetailerManagement extends BaseApi implements IRetailerManagement {
	// Address Requests
	async upsertAddress(sellerId: string, data: AddressDto): Promise<boolean> {
		let resp = await this.request(
			`sellers/${sellerId}/address`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	// Modalities Requests
	async getModalities(sellerId: string): Promise<SellerModalityDto[]> {
		let resp = await this.request<SellerModalityDto[]>(
			`sellers/${sellerId}/modalities`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error(`Could not get modalities for sellerId: ${sellerId}.`);

		return resp.data;
	}

	async updateModalities(
		sellerId: string,
		data: SellerModalityDto[]
	): Promise<boolean> {
		let resp = await this.request(
			`sellers/${sellerId}/modalities`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	// WorkingHours requests
	async getWorkingHours(sellerId: string): Promise<SellerWorkingHoursDto[]> {
		let resp = await this.request<SellerWorkingHoursDto[]>(
			`sellers/${sellerId}/workinghours`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error(`Could not get working hours for sellerId: ${sellerId}.`);

		return resp.data;
	}

	async upsertWorkingHours(
		sellerId: string,
		data: SellerWorkingHoursDto[]
	): Promise<boolean> {
		let resp = await this.request(
			`sellers/${sellerId}/workinghours`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	// Integration Config Requests
	async getIntegrationConfig(
		sellerId: string,
		integrationConfigId: string
	): Promise<SellerIntegrationDto> {
		let resp = await this.request<SellerIntegrationDto>(
			`sellers/${sellerId}/integrationconfig/${integrationConfigId}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error(
				`Could not get integration config for sellerId: ${sellerId}, integrationConfigId: ${integrationConfigId}.`
			);

		return resp.data;
	}

	async upsertIntegrationConfig(
		sellerId: string,
		data: SellerIntegrationDto
	): Promise<boolean> {
		let resp = await this.request(
			`sellers/${sellerId}/integrationconfig`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	async getOrderProcesses(): Promise<SellerOrderProcessDto[]> {
		let resp = await this.request<SellerOrderProcessDto[]>(
			`autocomplete/processes/sellers`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error("Could not get autocomplete order processes.");

		return resp.data;
	}

	async upsertOrderProcess(
		sellerId: string,
		data: SellerOrderProcessDto
	): Promise<boolean> {
		let resp = await this.request(
			`sellers/${sellerId}/process`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}

	// Seller Requests
	async getSeller(sellerId: string): Promise<SellerDto> {
		let resp = await this.request<SellerDto>(
			`sellers/${sellerId}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error("Could not get seller.");

		return resp.data;
	}

	async createSeller(data: SellerBasicInfoDto): Promise<boolean> {
		let resp = await this.request(`sellers/`, HttpMethod.Post, data);

		return this.isResponseSuccessful(resp.status);
	}

	async updateSeller(sellerId: string, data: SellerDto): Promise<boolean> {
		let resp = await this.request(`sellers/${sellerId}`, HttpMethod.Put, data);

		return this.isResponseSuccessful(resp.status);
	}

	async getSellerClusters(): Promise<SellerClusterDto[]> {
		let resp = await this.request<SellerClusterDto[]>(
			`sellerClusters`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error("Could not get seller clusters.");

		return resp.data;
	}

	async getLayoutProfile(): Promise<LayoutProfile> {
		let resp = await this.request<LayoutProfile>(
			"layoutProfiles",
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get layout profile");
		}
		return resp.data;
	}

	async getAutocompleteSellers(): Promise<AutocompleteSellerDto[]> {
		let resp = await this.request<AutocompleteSellerDto[]>(
			"autocomplete/sellers",
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get autocomplete sellers");
		}

		return resp.data;
	}

	async getStoreList(queryParams: string): Promise<StoresDto> {
		let resp = await this.request<StoresDto>(
			`sellers?${queryParams}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status))
			throw new Error("Could not get store list.");

		return resp.data;
	}

	async deleteStore(storeId: string): Promise<boolean> {
		let resp = await this.request(`sellers/${storeId}`, HttpMethod.Delete);

		return this.isResponseSuccessful(resp.status);
	}
}

export const retailerManagementApi: IRetailerManagement =
	new RetailerManagement("management/retailer/");

import React from "react";
import OrderFlowCancelModal from "./OrderModal/OrderFlowCancelModal";
import OrderFlowModal from "./OrderModal/OrderFlowModal";
import InvoiceOrderModal from "./OrderModal/InvoiceOrderModal";
import PrepareShippingModal from "./OrderModal/PrepareShippingModal";
import ChangeDataWithdrawalModal from "./OrderModal/ChangeDataPickupInStoreModal";
import { useOrders } from "../providers/OrdersContext";
import { i18n } from "../../../translate/i18n";
import { formatPhoneNumber, formatDocument } from "../../../infrastructure/utils/FormatMask";

export default function ButtonStatus({
	icons,
	title,
	info,
	style,
	disabled,
	integration,
	position,
	isError,
	name,
	cancelDisabled,
}) {
	const {
		handleChangeStatus,
		setLoadingStatus,
		order,
		orderId,
		handleChangeTrackings,
	} = useOrders();

	const hanldeReprocessOrder = () => {
		if (name === "pickUpInStore") {
			setLoadingStatus(true);
			const formTracking = [
				{
					addresseeName: order?.Buyer?.FullName,
					addresseePhoneNumber: formatPhoneNumber(order?.Buyer?.PhoneNumber),
					addresseeEmail: order?.Buyer?.Email,
					documentPickup: formatDocument(
						order?.Buyer?.DocumentType,
						order?.Buyer?.Document
					),
					invoiceNumber: order?.Invoices[0]?.Number,
					carrierName: order?.ShippingData?.Shipment?.CarrierName,
					shippingReference: order?.ShippingData?.Shipment?.ShippingReference,
					deliveryDate: new Date().toISOString(),
				},
			];
			const button = document.getElementById("changeDataWithdrawall");
			button.click();
			handleChangeTrackings(orderId, formTracking);
		} else if (!name) {
			setLoadingStatus(true);
			handleChangeStatus(position, isError);
		}
	};

	return (
		<>
			<div className={cancelDisabled ? `btn-tooltip m-0 p-0` : `m-0 p-0`}>
				<button
					type="button"
					name={name}
					className={`btn-status ${style} p-1`}
					disabled={disabled}
					style={{ zIndex: "100" }}
					data-toggle={name && "modal"}
					data-target={name}
					onClick={hanldeReprocessOrder}
				>
					<div
						className="row d-flex align-items-center flex-nowrap"
						style={{ zIndex: "100" }}
					>
						<div className="col-1 ml-3 p-0">
							<div className={`icon-status ${style}`}>
								<i className="material-icons-outlined">{icons}</i>
							</div>
						</div>

						<div className="col-8 m-0 p-0 ml-2 pl-2" style={{ width: "115px" }}>
							<div className="d-flex flex-column text-left pl-2 pr-0 m-0">
								<p className={`text-status ${style} m-0 p-0`}>{title}</p>
								<p className={`text-information ${style} m-0 p-0`}>{info}</p>
							</div>
						</div>

						{integration === "failed" && (
							<div className="col-1 neo-tooltip m-0 p-0">
								<div
									className="tooltip-icon rounded-circle"
									style={{
										marginRight: "-8px",
										background: "#FF5B5C",
										padding: "1px",
										border: "2px solid #fff",
									}}
								>
									<i className="material-icons outlined">error_outline</i>
								</div>
								<span className="neo-tooltip-msg">
									{i18n.t("orderDetails.Messages.IntegrationFailed")}
								</span>
							</div>
						)}

						{integration === "retry" && (
							<div className="col-1 neo-tooltip m-0 p-0">
								<div
									className="tooltip-icon rounded-circle"
									style={{
										marginRight: "-8px",
										background: "#7A9FEB",
										padding: "1px",
										border: "2px solid #fff",
									}}
								>
									<i className="material-icons outlined">sync</i>
								</div>
								<span className="neo-tooltip-msg">
									{i18n.t("orderDetails.Status.Retry")}
								</span>
							</div>
						)}

						{integration === "process" && (
							<div className="col-1 neo-tooltip m-0 p-0">
								<div
									className="tooltip-icon p-1 rounded-circle"
									style={{
										marginRight: "-10px",
										background: "#7A9FEB",
										border: "2px solid #fff",
									}}
								>
									<i
										className="fas fa-spinner fa-spin"
										style={{ fontSize: "10px" }}
									></i>
								</div>
								<span className="neo-tooltip-msg">
									{i18n.t("orderDetails.Status.Processing")}
								</span>
							</div>
						)}
					</div>
				</button>
				{cancelDisabled && (
					<span className="msg-tooltip">
						{i18n.t("orderDetails.Messages.CanNotInvoice")}
					</span>
				)}
			</div>
			<OrderFlowModal position={position} />
			<OrderFlowCancelModal position={9} />
			<InvoiceOrderModal />
			<PrepareShippingModal />
			<ChangeDataWithdrawalModal />
		</>
	);
}

import React, { useEffect, useState } from "react";
import NewDropImage from "../../../../components/NewDropImage";
import "react-widgets/dist/css/react-widgets.css";
import Modal from "../../../../components/Modal";
import Loading from "../../../../components/Loading";
import ContentLoader from "react-content-loader";
import { useHistory } from "react-router-dom";
import { ButtonGroup, Button } from "@mui/material";
import { DeleteOutline, Edit, Inventory2Outlined } from "@mui/icons-material";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";

export default function SkuProducts(props) {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [sku, setSku] = useState({
		edit: false,
		isActive: true,
		code: "",
		auxCode: "",
		barcode: "",
		name: "",
		hexColor: "",
		imageUrl: "",
		images: [],
		skudId: null,
		id: "",
	});

	const [DeleteSKU, setDeleteSKU] = useState("");

	useEffect(() => {
		setSku((prevState) => {
			return { ...prevState, id: props.id };
		});
	}, []);

	const clean = () => {
		setLoading(true);
		setTimeout(() => {
			setSku({
				edit: false,
				isActive: true,
				code: "",
				auxCode: "",
				barcode: "",
				name: "",
				hexColor: "",
				imageUrl: "",
				images: [],
				skudId: null,
			});
			setLoading(false);
		}, 1000);
	};

	const getStock = (id, name) => {
		setSku((prevState) => {
			return { ...prevState, skuId: id, skuName: name };
		});
	};

	const getSku = (item) => {
		setSku((prevState) => {
			return {
				...prevState,
				edit: true,
				isActive: item.isActive,
				name: item.name,
				id: item.id,
				code: item.code,
				barcode: item.barcode,
				images: item.images,
			};
		});
	};

	const create = (e) => {
		var button = document.getElementById("create");
		button.setAttribute("actived", "actived");

		let type = "edit";
		type = "create";

		props.create(sku, type);
		//clean()
		setSku({
			edit: false,
			isActive: true,
			code: "",
			auxCode: "",
			barcode: "",
			name: "",
			hexColor: "",
			imageUrl: "",
			images: [],
			skudId: null,
		});
	};

	const recoveryStateImage = (e) => {
		setSku((prevState) => {
			return { ...prevState, images: e };
		});
		if (e.length > 0) {
			sku.imageUrl = e[0].imageUrl;
		}
		for (let i = 0; i < sku.images.length; i++) {
			sku.images[i].order = i;
		}
	};

	const changeBool = (e) => {
		setSku((prevState) => {
			return { ...prevState, isActive: !sku.isActive };
		});
	};
	const handleChange = (e) => {
		e.persist();
		setSku((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};
	const setDelete = (SKU) => {
		setDeleteSKU(SKU);
	};
	const deleteSku = () => {
		props.delete(DeleteSKU);
	};

	const renderScreen = () => {
		return (
			<table className="table">
				<thead>
					<tr>
						<th scope="col">Nome</th>
						<th scope="col">Código</th>
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					{props.skus.map((item, i) => (
						<tr
							key={item.id ? item.id : item.code}
							id={item.id ? item.id : item.code}
						>
							<td>{item.name}</td>
							<td>{item.code}</td>
							<td className="text-right">
								<ButtonGroup color="light" variant="outlined">
									<Button
										sx={{ height: "36px" }}
										onClick={() =>
											history.push(
												`${props.id}/sku/${item.id ? item.id : item.code}`
											)
										}
									>
										<CustomIcon
											variant="small"
											color="blueish80"
											icon={<Edit />}
										/>
									</Button>
									<Button
										sx={{ height: "36px" }}
										onClick={() =>
											history.push(
												`/catalog/management/inventory/1?query=${item.code}`
											)
										}
									>
										<CustomIcon
											variant="small"
											color="blueish80"
											icon={<Inventory2Outlined />}
										/>
									</Button>
									<Button
										sx={{ height: "36px" }}
										data-toggle="modal"
										data-target="#deleteSku"
										onClick={() => setDelete(item)}
									>
										<CustomIcon
											variant="small"
											color="danger"
											icon={<DeleteOutline />}
										/>
									</Button>
								</ButtonGroup>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	const skeleton = () => {
		const skele = [0, 1, 2];
		return skele.map(() => (
			<ContentLoader
				speed={2}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="16" y="25" rx="3" ry="3" width="115" height="10" />
				<rect x="450" y="26" rx="3" ry="3" width="60" height="10" />
				<rect x="750" y="26" rx="3" ry="3" width="52" height="10" />
				<rect x="970" y="17" rx="3" ry="3" width="100" height="35" />
			</ContentLoader>
		));
	};

	return (
		<div className="card mt-3">
			<div className="card-header d-flex justify-content-between align-items-center">
				<h4 className="card-title">Variação do Produto (SKU)</h4>
				<div className="card-actions text-right">
					<Button
						color="grey80"
						variant="contained"
						data-toggle="modal"
						data-target="#variationModal"
						onClick={() => clean()}
					>
						Criar nova variação
					</Button>
				</div>
			</div>
			<div className="card-content">
				<div className="card-body pt-0">
					{props.loading ? skeleton() : renderScreen()}
				</div>
			</div>
			<Modal
				id={"variationModal"}
				large={true}
				title={sku.edit ? "Editar variação" : "Criar variação"}
				create={!sku.edit}
				edit={sku.edit}
				removeCloseButton={true}
				content={
					<div className="row">
						{props.loading ? (
							<Loading />
						) : (
							<>
								<div className="form-group col-10">
									<label htmlFor="name">
										<strong>Nome da variação</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="name"
											placeholder="Preto"
											name="name"
											defaultValue={sku.name}
											onChange={(e) => handleChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group col-2">
									<label htmlFor="limiteUso">
										<strong>Visibilidade</strong>
									</label>
									<br />
									<label className="switch ">
										<input
											type="checkbox"
											name="viewMap"
											checked={sku.isActive}
											onChange={changeBool}
										/>
										<span className="slider round"></span>
									</label>
								</div>
								<div className="form-group col-6">
									<label htmlFor="code">
										<strong>Código da variação</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="code"
											placeholder="0"
											name="code"
											defaultValue={sku.code}
											onChange={(e) => handleChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group col-6">
									<label htmlFor="auxCode">
										<strong>Código Auxiliar</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="auxCode"
											placeholder="0"
											name="auxCode"
											defaultValue={sku.auxCode}
											onChange={(e) => handleChange(e)}
											required
										/>
									</div>
								</div>
								<div className="form-group col-12">
									<label htmlFor="barcode">
										<strong>Código de barras</strong>
									</label>
									<div>
										<input
											type="text"
											className="form-control"
											id="barcode"
											placeholder="0"
											name="barcode"
											defaultValue={sku.barcode}
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>
								<div className="form-group col-12 ">
									<NewDropImage
										type={"carrousel"}
										data={sku.images}
										recoveryStateLogo={recoveryStateImage}
										height={"90px"}
										width={"100%"}
										background={"#F2F4F4"}
									/>
								</div>
							</>
						)}
					</div>
				}
				buttonId={"create"}
				actionCreate={create}
				actionEdit={create}
			/>
			<Modal
				id={"deleteSku"}
				title={"Certeza que deseja excluir este item?"}
				delete={true}
				actionDelete={deleteSku}
			/>
		</div>
	);
}

import React, { useState } from "react";
import { useOrders } from "../../providers/OrdersContext";
import { APIV2 } from "../../../../API";
import ReactPDF from "@react-pdf/renderer";
import TrackingOrdersModal from "../TrackingOrdersModal";
import NewToastComponent from "../../../../components/NewToastComponent";
import CourierLabel from "../../../../components/CourierLabel/CourierLabel.tsx";
import { formatDateStringOnly } from "../../../../infrastructure/utils/FormatMask";

export default function TrackingOrders({ id }) {
	const {
		tracking,
		setTracking,
		detailOrder,
		setDetailOrder,
		invoice,
		plp,
		freight,
	} = useOrders();
	const [loadingLabel, setLoadingLabel] = useState(false);
	const [copyLinkActived, setCopyLinkActived] = useState(false);
	const [copyCodeActived, setCopyCodeActived] = useState(false);

	const copyToClipboard = (text, type) => {
		navigator.clipboard.writeText(text);
		if (type === "code") {
			setCopyCodeActived(true);
			setTimeout(() => setCopyCodeActived(false), 3000);
			return;
		}
		setCopyLinkActived(true);
		setTimeout(() => setCopyLinkActived(false), 3000);
	};

	const handleLabelError = () => {
		setLoadingLabel(false);
		NewToastComponent({
			status: "error",
			title: "Não foi possível gerar a etiqueta.",
			message: "Tente novamente!",
		});
	};

	const handleLabelSuccess = (data) => {
		const courierObject = createCourierObject(data);
		new Promise(async (resolve) => {
			const blob = await ReactPDF.pdf(
				<CourierLabel courierObject={courierObject} />
			).toBlob();
			const url = URL.createObjectURL(blob);
			if (url.length > 0) {
				resolve(url);
			}
		})
			.then((generatedUrl) => {
				setLoadingLabel(false);
				if (generatedUrl) {
					let aTag = document.createElement("a");
					aTag.href = generatedUrl;
					aTag.style = "display: none";
					aTag.target = "_blank";
					document.body.appendChild(aTag);
					aTag.dispatchEvent(new MouseEvent("click"));
				}
				NewToastComponent({
					status: "success",
					title:
						"A etiqueta de postagem foi gerada e seu arquivo baixado com sucesso!",
				});
			})
			.catch(() => handleLabelError());
	};

	const labelDownload = async () => {
		setLoadingLabel(true);
		if (!tracking?.shippingCode && !tracking?.shippingType) {
			APIV2.patch(`management/commerce/orders/${id}/shippingcode`)
				.then((response) => {
					if (!response.data.tracking?.shippingCode || !response.data.plp) {
						handleLabelError();
						return;
					}
					setDetailOrder(response.data);
					setTracking(response.data.tracking);
					handleLabelSuccess(response.data);
				})
				.catch(() => handleLabelError());
			return;
		}

		if (!detailOrder.tracking?.shippingCode || !detailOrder.plp) {
			handleLabelError();
			return;
		}

		handleLabelSuccess(detailOrder);
	};

	const createCourierObject = (data) => {
		const weight = data.items.reduce(
			(acc, currentItem) =>
				acc + currentItem.quantity * currentItem.sku.product.weight,
			0
		);
		const foundContract = data.customData?.find(
			(custom) => custom.key === "CORREIOS_NUMBER"
		);
		let contractValue = "";
		if (foundContract) contractValue = foundContract.value;
		const object = {
			weight: weight,
			invoice: data.invoice.number,
			contract: contractValue,
			shippingType: data.tracking.shippingType,
			plp: data.plp,
			trackingCode: data.tracking.trackingCode,
			recipientFullName: data.buyer.fullName,
			senderfullName: data.seller.fullName,
			recipientAddress: {
				street: data.deliveryAddress.street,
				number: data.deliveryAddress.number,
				complement: data.deliveryAddress.complement,
				reference: data.deliveryAddress.reference,
				neighborhood: data.deliveryAddress.neighborhood,
				zipCode: data.deliveryAddress.cep,
				city: data.deliveryAddress.city,
				state: data.deliveryAddress.state,
			},
			senderAddress: {
				street: data.seller.address.street,
				number: data.seller.address.number,
				complement: data.seller.address.complement,
				reference: data.seller.address.reference,
				neighborhood: data.seller.address.neighborhood,
				zipCode: data.seller.address.cep,
				city: data.seller.address.city,
				state: data.seller.address.state,
			},
		};

		return object;
	};

	const renderCourierLabelButton = () => {
		if (loadingLabel) {
			return (
				<i
					className="fas fa-spinner fa-spin"
					style={{ alignSelf: "center !important" }}
				></i>
			);
		}
		if (plp) {
			return (
				<>
					<i
						class="material-icons-outlined"
						style={{
							paddingLeft: "1em",
							marginRight: "-.6em",
							paddingTop: "-.5em",
						}}
					>
						download
					</i>
					Baixar etiqueta de postagem
				</>
			);
		}
		return (
			<>
				<i
					class="material-icons-outlined"
					style={{
						paddingLeft: "1em",
						marginRight: "-.6em",
						paddingTop: "-.5em",
					}}
				>
					text_snippet
				</i>
				Emitir e baixar etiqueta de postagem
			</>
		);
	};

	return (
		<div className="d-flex flex-column">
			<TrackingOrdersModal idOrder={id} />
			<div className="box-title m-0 mt-3">
				<h2 className="card-title">FORMA DE ENTREGA</h2>
			</div>
			<div className="d-flex flex-column m-0 p-0 mb-4">
				{freight?.description && (
					<span className="text-orders">
						<strong>Tipo de envio: </strong> {freight?.description}
					</span>
				)}

				{freight?.estimateDate && (
					<span className="text-orders">
						<strong>Estimativa para entrega: </strong>
						{formatDateStringOnly(freight.estimateDate)}
					</span>
				)}

				{tracking?.carrierName && (
					<span className="text-orders mt-1">
						<strong>Transportadora: </strong> {tracking?.carrierName}
					</span>
				)}

				{tracking?.trackingLink && (
					<span className="text-orders mt-1 d-flex flex-row">
						<strong>Link de Rastreio: </strong>
						<div className="tracking-box">
							<a href={tracking?.trackingLink} target="_blank">
								Link
							</a>
							{!copyLinkActived && (
								<button
									onClick={() =>
										copyToClipboard(tracking?.trackingLink, "link")
									}
								>
									<i className="material-icons">content_copy</i>
								</button>
							)}
							{copyLinkActived && (
								<button
									onClick={() =>
										copyToClipboard(tracking?.trackingLink, "link")
									}
								>
									<i style={{ color: "#6CD691" }} className="material-icons">
										check_circle
									</i>
								</button>
							)}
						</div>
					</span>
				)}

				{tracking?.deliveryDate && (
					<span className="text-orders mt-1">
						<strong>Data de Entrega: </strong> {tracking?.deliveryDate}
					</span>
				)}

				{tracking?.trackingCode && (
					<span className="text-orders d-flex flex-row mt-1">
						<strong>Código de Rastreio: </strong>
						<div className="tracking-box">
							<label className="ml-1">{tracking?.trackingCode}</label>
							{!copyCodeActived && (
								<button
									onClick={() =>
										copyToClipboard(tracking?.trackingCode, "code")
									}
								>
									<i className="material-icons">content_copy</i>
								</button>
							)}
							{copyCodeActived && (
								<button
									onClick={() =>
										copyToClipboard(tracking?.trackingCode, "code")
									}
								>
									<i style={{ color: "#6CD691" }} className="material-icons">
										check_circle
									</i>
								</button>
							)}
						</div>
					</span>
				)}
			</div>

			{tracking ? (
				<button
					className="editing-tracking w-100 "
					data-toggle="modal"
					data-target="#trackingOrders"
				>
					Alterar informações de rastreio
				</button>
			) : (
				<button
					className="create-tracking w-100"
					data-toggle="modal"
					data-target="#trackingOrders"
				>
					Inserir dados de Rastreio
				</button>
			)}
			{invoice?.key && (
				<a
					target="_blank"
					className={`btn btn-grey-80 btn-block ${
						!loadingLabel ? "btn-icon-l" : "disabled"
					}`}
					style={{
						color: "#fff",
						width: "auto",
						marginTop: ".5em",
						paddingTop: "1em",
						paddingBottom: "1em",
					}}
					onClick={() => labelDownload()}
				>
					{renderCourierLabelButton()}
				</a>
			)}
		</div>
	);
}

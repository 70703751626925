import React, { useEffect, useState } from "react";

// import { Container } from './styles';

function NewPaginationComponent({
	rowsPerPage,
	rowCount,
	onChangePage,
	onChangeRowsPerPage,
	currentPage,

	changeSelectOptions,
	getPagesArray,
	pageValue,
	// returnInputChange,
}) {
	const pages = Math.ceil(rowCount / rowsPerPage);
	const pageItems = getPagesArray(pages);
	const nextDisabled = currentPage === pageItems.length;
	const previousDisabled = currentPage === 1;
	const perPageSelectOptions = changeSelectOptions
		? changeSelectOptions
		: [20, 25, 30, 35, 40, 45, 50];
	const lastIndex = currentPage * rowsPerPage;
	const firstIndex = lastIndex - rowsPerPage + 1;
	const [pageInputValue, setPageInputValue] = useState(pageValue);
	// const range =
	// 	currentPage === pages
	// 		? `${firstIndex}-${rowCount} de ${rowCount}`
	// 		: `${firstIndex}-${lastIndex} de ${rowCount}`;

	const showOuterPages = [
		1,
		2,
		3,
		"...",
		pageItems.length - 2,
		pageItems.length - 1,
		pageItems.length,
	];

	const showInnerPages = [
		1,
		"...",
		currentPage - 1,
		currentPage,
		currentPage + 1,
		"...",
		pageItems.length,
	];

	const handleBackButtonClick = () => {
		onChangePage(currentPage - 1);
	};

	const handleNextButtonClick = () => {
		onChangePage(currentPage + 1);
	};

	const handlePageNumber = (e) => {
		onChangePage(Number(e.target.value));
	};

	const handleRowsPerPage = (e) => {
		onChangeRowsPerPage(Number(e.target.value), currentPage);
	};

	const handleInputChange = (e) => {
		let value = e.target.value;
		const re = /^[0-9\b]+$/;

		if (
			e.target.value.length <= pages.toString().length &&
			(e.target.value === "" || re.test(e.target.value))
		) {
			setPageInputValue(value);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			changeToPage();
		}
	};

	const changeToPage = () => {
		if (pageInputValue < 1) {
			onChangePage(1);
			return;
		}
		if (pageInputValue > pageItems.length) {
			onChangePage(pageItems.length);
			return;
		}
		onChangePage(pageInputValue);
	};

	return (
		<nav className="pagination-nav">
			<div className="pagination-rows">
				<span className="pagination-rows-span">Visualizar</span>
				<span className="pagination-rows-select-area">
					<select
						className="pagination-rows-select-box"
						onChange={handleRowsPerPage}
						defaultValue={rowsPerPage}
					>
						{perPageSelectOptions.map((num) => (
							<option key={num} value={num}>
								{num}
							</option>
						))}
					</select>
				</span>
				<span className="pagination-rows-span">itens por página</span>
			</div>
			<span className="pagination-divider">|</span>
			<span className="pagination-range">
				{currentPage === pages
					? `${firstIndex}-${rowCount} de ${rowCount}`
					: `${firstIndex}-${lastIndex} de ${rowCount}`}
			</span>
			<ul className="pagination-page-list">
				<li>
					<button
						className={`pagination-page-list-change-button ${
							previousDisabled ? "disabled" : ""
						}`}
						onClick={handleBackButtonClick}
						disabled={previousDisabled}
						aria-disabled={previousDisabled}
						aria-label="previous page"
					>
						<i className="material-icons">chevron_left</i>
					</button>
				</li>
				{pageItems.length > 7
					? currentPage > 3 && currentPage < pageItems.length - 2
						? showInnerPages.map((page) => {
								const conditionalClasses = `${
									page === currentPage
										? "disabled current-page"
										: page === "..."
										? "disabled"
										: ""
								}`;
								return (
									<li key={page}>
										<button
											className={`pagination-page-list-button ${conditionalClasses}`}
											disabled={page === "..." || page === currentPage}
											onClick={handlePageNumber}
											value={page}
										>
											{page}
										</button>
									</li>
								);
						  })
						: showOuterPages.map((page) => {
								const conditionalClasses = `${
									page === currentPage
										? "disabled current-page"
										: page === "..."
										? "disabled"
										: ""
								}`;

								return (
									<li key={page}>
										<button
											className={`pagination-page-list-button ${conditionalClasses}`}
											disabled={page === "..." || page === currentPage}
											onClick={handlePageNumber}
											value={page}
										>
											{page}
										</button>
									</li>
								);
						  })
					: pageItems.map((page) => {
							const conditionalClasses = `${
								page === currentPage ? "disabled current-page" : ""
							}`;

							return (
								<li key={page}>
									<button
										className={`pagination-page-list-button ${conditionalClasses}`}
										disabled={page === currentPage}
										onClick={handlePageNumber}
										value={page}
									>
										{page}
									</button>
								</li>
							);
					  })}
				<li>
					<button
						className={`pagination-page-list-change-button ${
							nextDisabled ? "disabled" : ""
						}`}
						onClick={handleNextButtonClick}
						disabled={nextDisabled}
						aria-disabled={nextDisabled}
						aria-label="next page"
					>
						<i className="material-icons">chevron_right</i>
					</button>
				</li>
			</ul>
			<div className="pagination-go-to">
				<span>Ir para a página</span>
				<div className="pagination-go-to-box">
					<input
						type="text"
						className={`go-to-input ${
							pageItems.length === 1 ? "disabled" : ""
						}`}
						disabled={pageItems.length === 1}
						onChange={handleInputChange}
						onKeyPress={handleKeyPress}
						value={pageInputValue}
					/>
					<button
						onClick={changeToPage}
						className={`go-to-button ${
							pageItems.length === 1 ? "disabled" : ""
						}`}
						disabled={pageItems.length === 1}
					>
						<i className="material-icons">arrow_forward</i>
					</button>
				</div>
			</div>
		</nav>
	);
}

export default NewPaginationComponent;

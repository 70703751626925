import { CpOrderDto } from "../../dtos/cpCommerceManagement/CpOrderDto";
import { DocumentType } from "../../enums/DocumentType";
import { ListOrderModel } from "../../models/commerce/OrderList/ListOrderModel";
import { showOrderCode } from "../../../infrastructure/utils/OrdersUtils";
import { CpOrderStatusDto } from "../../dtos/cpCommerceManagement/CpOrderStatusDto";

import mastercard from "../../../assets/payments-flags/icon-master-24px.svg";
import visa from "../../../assets/payments-flags/icon-visa-24px.svg";
import creditcard from "../../../assets/payments-flags/credit_card.svg";
import elo from "../../../assets/payments-flags/icon-elo.svg";
import americanexpress from "../../../assets/payments-flags/icon-americanexpress-24px.svg";
import withdrawal from "../../../assets/payments-flags/icon-pag-retirada.svg";
import boleto from "../../../assets/payments-flags/icon-pag-boleto.svg";
import voucher from "../../../assets/payments-flags/icon-pag-voucher.svg";
import pix from "../../../assets/payments-flags/icon-pag-pix.svg";
import linkpag from "../../../assets/payments-flags/icon-link-pag.svg";
import installmentPix from "../../../assets/payments-flags/icon-pix-parcelado.svg";
import paymentTerminal from "../../../assets/payments-flags/icon-paymentTerminal.svg";
import awaitMethod from "../../../assets/payments-flags/update_black_24dp.svg";
import twocards from "../../../assets/payments-flags/icon-two-cards.svg";
import { OrderDto } from "../../dtos/smOrders/OrderDto";
import { OrderStatus } from "../../enums/OrderStatus";
import { PaymentType } from "../../enums/PaymentType";
import {
	LegacyPaymentStatus,
	LegacyPaymentStatusDescription,
	mapLegacyPaymentStatusToDescription,
} from "../../enums/LegacyOrders/LegacyPaymentStatus";
import { i18n } from "../../../translate/i18n";
import { PaymentDto } from "../../dtos/smOrders/payments/PaymentDto";
import { SMPaymentTypeDescription } from "../../enums/PaymentTypeSM";
import { PaymentStatus } from "../../enums/PaymentStatus";
import { isValueInStringEnum } from "../../../infrastructure/utils/TypeValidator";

const defaultEstimateDate = "0001-01-01T00:00:00";

const validateEstimateDate = (date: string | null): string | null => {
	return date === defaultEstimateDate ? null : date;
};

const getCardBrandIcon = (paymentBrand: string | null): string => {
	if (!paymentBrand) return creditcard;
	const brandIcons: { [key: string]: string } = {
		mastercard: mastercard,
		master: mastercard,
		visa: visa,
		hipercard: creditcard,
		elo: elo,
		amex: americanexpress,
		"american express": americanexpress,
		americanexpress: americanexpress,
		diners: creditcard,
		sorocred: creditcard,
	};
	return brandIcons[paymentBrand.toLowerCase()] || creditcard;
};

const legacyToListOrderModel = (orders: CpOrderDto[]): ListOrderModel[] => {
	const statusIcons: { [key in CpOrderStatusDto]: string } = {
		[CpOrderStatusDto.None]: "add_comment",
		[CpOrderStatusDto.AwaitingCreation]: "timelapse",
		[CpOrderStatusDto.CreatedInternal]: "add_comment",
		[CpOrderStatusDto.CreatedExternal]: "storefront",
		[CpOrderStatusDto.InProgress]: "add_comment",
		[CpOrderStatusDto.AwaitingInvoiceCreation]: "receipt_long",
		[CpOrderStatusDto.InvoiceCreated]: "receipt",
		[CpOrderStatusDto.Separated]: "content_paste",
		[CpOrderStatusDto.ReadyForPickup]: "shopping_bag",
		[CpOrderStatusDto.Shipping]: "local_shipping",
		[CpOrderStatusDto.Finalized]: "check_circle_outline",
		[CpOrderStatusDto.Canceled]: "highlight_off",
		[CpOrderStatusDto.Error]: "more_horiz",
	};

	const validadeIfDelayed = (
		estimateDate: string | null,
		status: CpOrderStatusDto
	): boolean => {
		if (!estimateDate || estimateDate === defaultEstimateDate) return false;
		const currentDate = new Date();
		const estimated = new Date(estimateDate);
		if (currentDate < estimated) return false;

		const neverDelayedStatuses = [
			CpOrderStatusDto.ReadyForPickup,
			CpOrderStatusDto.Shipping,
			CpOrderStatusDto.Finalized,
			CpOrderStatusDto.Canceled,
		];
		return !neverDelayedStatuses.includes(status);
	};

	const getDocumentType = (order: CpOrderDto): DocumentType => {
		if (order.cnpjBuyer) return DocumentType.Cnpj;
		return order.cpfBuyer ? DocumentType.Cpf : DocumentType.None;
	};

	const getDocument = (order: CpOrderDto): string => {
		if (order.cnpjBuyer) return order.cnpjBuyer;
		return order.cpfBuyer ?? "";
	};

	const getOrderCode = (order: CpOrderDto): string =>
		showOrderCode(order.friendlyCode, order?.externalId, order?.sellerOrderId);

	const getStatusIcon = (status: CpOrderStatusDto): string =>
		statusIcons[status] || statusIcons[CpOrderStatusDto.None];

	const getStatusLabel = (status: CpOrderStatusDto): string => {
		return isValueInStringEnum(CpOrderStatusDto, status)
			? i18n.t(`orderTable.LegacyStatus.${status}`)
			: i18n.t(`orderTable.LegacyStatus.${CpOrderStatusDto.None}`);
	};

	const getPaymentIcon = (
		paymentMethodType: PaymentType | null,
		paymentBrand: string | null
	): string => {
		const paymentIcons: { [key in PaymentType]: string } = {
			[PaymentType.None]: awaitMethod,
			[PaymentType.CreditCard]: getCardBrandIcon(paymentBrand),
			[PaymentType.PickupStore]: withdrawal,
			[PaymentType.Bankslip]: boleto,
			[PaymentType.Voucher]: voucher,
			[PaymentType.DebitCard]: getCardBrandIcon(paymentBrand),
			[PaymentType.Pix]: pix,
			[PaymentType.PaymentLink]: linkpag,
			[PaymentType.InstallmentPix]: installmentPix,
			[PaymentType.Cashback]: awaitMethod,
			[PaymentType.TerminalCreditCard]: paymentTerminal,
			[PaymentType.TerminalDebitCard]: paymentTerminal,
			[PaymentType.Invoice]: awaitMethod,
		};
		if (!paymentMethodType) return paymentIcons[PaymentType.None];

		return paymentIcons[paymentMethodType] || paymentIcons[PaymentType.None];
	};

	const getPaymentLabel = (
		paymentStatus: LegacyPaymentStatus,
		paymentType: PaymentType | null
	): string => {
		const paymentStatusDescription =
			mapLegacyPaymentStatusToDescription(paymentStatus);
		const isValidEnum = isValueInStringEnum(
			LegacyPaymentStatusDescription,
			paymentStatusDescription
		);
		const isPixPayment =
			paymentStatus === LegacyPaymentStatus.PaymentCodeCreated &&
			(paymentType === PaymentType.Pix ||
				paymentType === PaymentType.InstallmentPix);

		let textPath = `orderTable.LegacyPaymentStatus.${
			isValidEnum
				? paymentStatusDescription
				: LegacyPaymentStatusDescription.None
		}`;
		if (isPixPayment) {
			textPath = textPath + "Pix";
		}

		return i18n.t(textPath);
	};

	const genericOrderList: ListOrderModel[] = orders.map(
		(order: CpOrderDto): ListOrderModel => ({
			id: order.id,
			buyerFullName: order.fullName,
			documentType: getDocumentType(order),
			document: getDocument(order),
			lastUpdate: order.lastUpdate,
			orderCode: getOrderCode(order),
			createDate: order.createDate,
			statusClassName: `legacy-order-status-${order.status}`,
			statusIcon: getStatusIcon(order.status),
			statusLabel: getStatusLabel(order.status),
			productQuantity: order.quantity,
			isDelivery: order.isDelivery,
			carrierName: order?.freight?.description ?? null,
			estimateDate: validateEstimateDate(order?.freight?.estimateDate ?? null),
			isDelayed: validadeIfDelayed(
				order?.freight?.estimateDate ?? null,
				order.status
			),
			sellerFullName: order.salePointName,
			origin: order.channel,
			totalAmount: order.totalAmount,
			paymentIcon: getPaymentIcon(
				order.paymentInfo.paymentMethod?.type ?? null,
				order.paymentInfo.paymentMethod?.brand ?? null
			),
			paymentLabel: getPaymentLabel(
				order.paymentInfo.paymentStatus,
				order.paymentInfo.paymentMethod?.type ?? null
			),
		})
	);

	return genericOrderList;
};

const SMToListOrderModel = (orders: OrderDto[]): ListOrderModel[] => {
	const validadeIfDelayed = (
		estimateDate: string | null,
		status: OrderStatus
	): boolean => {
		if (!estimateDate || estimateDate === defaultEstimateDate) return false;
		const currentDate = new Date();
		const estimated = new Date(estimateDate);
		if (currentDate < estimated) return false;

		const neverDelayedStatuses = [
			OrderStatus.WaitingDelivery,
			OrderStatus.Finalized,
			OrderStatus.Canceled,
		];
		return !neverDelayedStatuses.includes(status);
	};

	const getDocumentType = (order: OrderDto): DocumentType => {
		return isValueInStringEnum(DocumentType, order.Buyer.DocumentType)
			? order.Buyer.DocumentType
			: DocumentType.None;
	};

	const getOrderCode = (order: OrderDto): string =>
		showOrderCode(order.FriendlyCode, order?.ExternalId, order?.SellerOrderId);

	const getStatusIcon = (status: OrderStatus, isDelivery: boolean): string => {
		const statusIcons: { [key in OrderStatus]: string } = {
			[OrderStatus.Created]: "new_releases",
			[OrderStatus.WaitingApproval]: "add_comment",
			[OrderStatus.WaitingSeparation]: "inbox",
			[OrderStatus.WaitingInvoice]: "receipt",
			[OrderStatus.WaitingDispatch]: isDelivery
				? "mark_email_unread"
				: "unarchive",
			[OrderStatus.WaitingDelivery]: isDelivery
				? "local_shipping"
				: "local_mall",
			[OrderStatus.WaitingReturnGoods]: "watch_later",
			[OrderStatus.ReturningGoods]: "swap_horiz",
			[OrderStatus.Finalized]: "check_circle_outline",
			[OrderStatus.WaitingCancel]: "watch_later",
			[OrderStatus.Canceled]: "highlight_off",
		};
		return statusIcons[status] || "add_comment";
	};

	const getStatusLabel = (status: OrderStatus, isDelivery: boolean): string => {
		const isValidEnum = isValueInStringEnum(OrderStatus, status);
		let textPath = `orderTable.SmStatus.${isValidEnum ? status : "Uninformed"}`;
		const isPickupStatus =
			(status === OrderStatus.WaitingDispatch ||
				status === OrderStatus.WaitingDelivery) &&
			!isDelivery;
		if (isPickupStatus) {
			textPath = textPath + "Pickup";
		}
		return i18n.t(textPath);
	};

	const getPaymentIcon = (payments: PaymentDto[]): string => {
		const paymentTypes = [];
		for (let payment of payments) {
			paymentTypes.push(payment.PaymentMethod.Type);
		}
		if (paymentTypes[0] === paymentTypes[1]) return twocards;

		const brand = payments[0]?.PaymentMethod?.Brand;
		const type = payments[0]?.PaymentMethod?.Type as SMPaymentTypeDescription;

		const paymentIcons: { [key in SMPaymentTypeDescription]: string } = {
			[SMPaymentTypeDescription.CreditCard]: getCardBrandIcon(brand),
			[SMPaymentTypeDescription.DebitCard]: getCardBrandIcon(brand),
			[SMPaymentTypeDescription.PickupStore]: withdrawal,
			[SMPaymentTypeDescription.Bankslip]: boleto,
			[SMPaymentTypeDescription.Voucher]: voucher,
			[SMPaymentTypeDescription.Cashback]: voucher,
			[SMPaymentTypeDescription.Pix]: pix,
			[SMPaymentTypeDescription.InstallmentPix]: installmentPix,
			[SMPaymentTypeDescription.PaymentLink]: linkpag,
			[SMPaymentTypeDescription.TerminalCreditCard]: paymentTerminal,
			[SMPaymentTypeDescription.TerminalDebitCard]: paymentTerminal,
			[SMPaymentTypeDescription.Invoice]: boleto,
		};

		return paymentIcons[type] || awaitMethod;
	};

	const getPaymentLabel = (paymentStatus: PaymentStatus): string => {
		return isValueInStringEnum(PaymentStatus, paymentStatus)
			? i18n.t(`orderTable.SmPaymentStatus.${paymentStatus}`)
			: i18n.t(`orderTable.SmPaymentStatus.NotStarted`);
	};

	const genericOrderList: ListOrderModel[] = orders.map(
		(order: OrderDto): ListOrderModel => ({
			id: order.id,
			buyerFullName: order.Buyer.FullName,
			documentType: getDocumentType(order),
			document: order.Buyer.Document,
			lastUpdate: order.LastUpdate,
			orderCode: getOrderCode(order),
			createDate: order.CreateDate,
			statusClassName: `order-status-${order.Status}`,
			statusIcon: getStatusIcon(order.Status, order.IsDelivery),
			statusLabel: getStatusLabel(order.Status, order.IsDelivery),
			productQuantity: order.Items.length,
			isDelivery: order.IsDelivery,
			carrierName: order.ShippingData.Shipment.CarrierName,
			estimateDate: validateEstimateDate(
				order.ShippingData.Shipment.EstimatedDeliveryDate
			),
			isDelayed: validadeIfDelayed(
				order.ShippingData.Shipment.EstimatedDeliveryDate,
				order.Status
			),
			sellerFullName: order.Seller?.FullName || order.Seller?.CompanyName || "",
			origin: order.Origin,
			totalAmount: order.Totals.TotalAmount,
			paymentIcon: getPaymentIcon(order.Payments),
			paymentLabel: getPaymentLabel(order.PaymentStatus),
		})
	);

	return genericOrderList;
};

export const ListOrderMapper = { legacyToListOrderModel, SMToListOrderModel };

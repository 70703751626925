import React, { ReactNode } from "react";
import { AlertContainer } from "./FixedAlertComponentStyles";
import { PaletteDiscriminatedUnion } from "../../theme/foundations/palette";
import CustomIcon from "../../MUICustomComponents/CustomIcon";
import { ErrorOutline } from "@mui/icons-material";

type Props = {
	children: ReactNode;
	alertType: "warning";
};

export default function FixedAlertComponent({ children, alertType }: Props) {
	const backgroundColors: {
		[key in Props["alertType"]]: PaletteDiscriminatedUnion;
	} = {
		warning: "orange10",
	};

	const icon: {
		[key in Props["alertType"]]: JSX.Element;
	} = {
		warning: <ErrorOutline />,
	};

	return (
		<AlertContainer color={alertType} bgColor={backgroundColors[alertType]}>
			<CustomIcon icon={icon[alertType]} variant="default" />
			{children}
		</AlertContainer>
	);
}

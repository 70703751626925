import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { DealerDto } from "../../domain/dtos/dealers/DealerDto";
import { CommercialConditionDto } from "../../domain/dtos/MarketingManagement/CommercialConditionDto";
import { ReceivingMethodDto } from "../../domain/dtos/MarketingManagement/ReceivingMethodDto";
import { ProductCommercialConditionDto } from "../../domain/dtos/MarketingManagement/ProductCommercialConditionDto";
import { ReceivingMethodConditionDto } from "../../domain/dtos/MarketingManagement/ReceivingMethodConditionDto";
import { CreatedResponse } from "../../domain/types/responses/CreatedResponse";

interface IMarketingManagement {
	getDealer: (dealerId: string) => Promise<DealerDto>;
	createDealer: (data: DealerDto) => Promise<boolean>;
	updateDealer: (dealerId: string, data: DealerDto) => Promise<boolean>;
	// CommercialConditions
	getConditions: () => Promise<CommercialConditionDto[]>;
	getConditionById: (conditionId: string) => Promise<CommercialConditionDto>;
	createCondition: (data: CommercialConditionDto) => Promise<CreatedResponse>;
	updateCondition: (
		conditionId: string,
		data: CommercialConditionDto
	) => Promise<boolean>;
	deleteCondition: (conditionId: string) => Promise<boolean>;
	// ReceivingMethodConditions
	getReceivingMethodConditionsByCommercialConditionId: (
		conditionId: string
	) => Promise<ReceivingMethodConditionDto[]>;
	modifyReceivingMethodInBatch: (
		data: ReceivingMethodConditionDto[]
	) => Promise<boolean>;
	// ProductCommercialCondition
	getProductCommercialConditionsByProductId: (
		productId: string
	) => Promise<ProductCommercialConditionDto>;
	upsertProductCommercialConditionInBatch: (
		data: ProductCommercialConditionDto[]
	) => Promise<boolean>;
	// ReceivingMethods
	getReceivingMethods: () => Promise<ReceivingMethodDto[]>;
}

class MarketingManagement extends BaseApi implements IMarketingManagement {
	async getDealer(dealerId: string): Promise<DealerDto> {
		let resp = await this.request<DealerDto>(
			`dealers/${dealerId}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get dealer.");
		}

		return resp.data;
	}

	async createDealer(data: DealerDto): Promise<boolean> {
		let resp = await this.request("dealers", HttpMethod.Post, data);

		return this.isResponseSuccessful(resp.status);
	}

	async updateDealer(dealerId: string, data: DealerDto): Promise<boolean> {
		let resp = await this.request(`dealers/${dealerId}`, HttpMethod.Put, data);

		return this.isResponseSuccessful(resp.status);
	}

	// CommercialConditions
	async getConditions(): Promise<CommercialConditionDto[]> {
		const resp = await this.request<CommercialConditionDto[]>(
			"commercial/conditions",
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get commercial condition list.");
		}

		return resp.data;
	}
	async getConditionById(conditionId: string): Promise<CommercialConditionDto> {
		const resp = await this.request<CommercialConditionDto>(
			`commercial/conditions/${conditionId}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error(`Could not get commercial condition ${conditionId}.`);
		}

		return resp.data;
	}
	async createCondition(
		data: CommercialConditionDto
	): Promise<CreatedResponse> {
		const resp = await this.request<CreatedResponse>(
			"commercial/conditions",
			HttpMethod.Post,
			data
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error(`Could not create commercial condition.`);
		}

		return resp.data;
	}
	async updateCondition(
		conditionId: string,
		data: CommercialConditionDto
	): Promise<boolean> {
		const resp = await this.request(
			`commercial/conditions/${conditionId}`,
			HttpMethod.Put,
			data
		);

		return this.isResponseSuccessful(resp.status);
	}
	async deleteCondition(conditionId: string): Promise<boolean> {
		const resp = await this.request(
			`commercial/conditions/${conditionId}`,
			HttpMethod.Delete
		);

		return this.isResponseSuccessful(resp.status);
	}

	// ReceivingMethodConditions
	async getReceivingMethodConditionsByCommercialConditionId(
		conditionId: string
	): Promise<ReceivingMethodConditionDto[]> {
		const resp = await this.request<ReceivingMethodConditionDto[]>(
			`commercial/conditions/${conditionId}/receivingMethods`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error(
				`Could not get ReceivingMethodConditions for commercial condition ${conditionId}.`
			);
		}

		return resp.data;
	}
	async modifyReceivingMethodInBatch(
		data: ReceivingMethodConditionDto[]
	): Promise<boolean> {
		const resp = await this.request<ReceivingMethodDto[]>(
			"commercial/conditions/receivingMethods/batch",
			HttpMethod.Put,
			data
		);
		return this.isResponseSuccessful(resp.status);
	}

	// ProductCommercialCondition
	async getProductCommercialConditionsByProductId(
		productId: string
	): Promise<ProductCommercialConditionDto> {
		const resp = await this.request<ProductCommercialConditionDto>(
			`commercial/conditions/products/${productId}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error(
				`Could not get commercial condition for product ${productId}.`
			);
		}

		return resp.data;
	}
	async upsertProductCommercialConditionInBatch(
		data: ProductCommercialConditionDto[]
	): Promise<boolean> {
		const resp = await this.request<ReceivingMethodDto[]>(
			"commercial/conditions/products/batch",
			HttpMethod.Put,
			data
		);
		return this.isResponseSuccessful(resp.status);
	}

	// Receiving Methods
	async getReceivingMethods(): Promise<ReceivingMethodDto[]> {
		const resp = await this.request<ReceivingMethodDto[]>(
			"receivingMethods",
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get receiving method list.");
		}

		return resp.data;
	}
}

export const marketingManagementApi: IMarketingManagement =
	new MarketingManagement("management/marketing/");

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { normalizeDigitsOnly } from "../../infrastructure/utils/StringUtils";
import { applyMask } from "../../infrastructure/utils/FormatUtils";
import countriesData from "./CountriesData.json";
import { PhoneMask, countryMaskRules } from "./CountryMaskRules";

export type CountryDataType = {
	code: string;
	label: string;
	phone: string;
	mask?: PhoneMask;
	maskByLengthRule?: (length: number) => PhoneMask;
};

export default function InternationalPhoneInputService(
	phoneNumber: string | null,
	onPhoneChange: (phone: string, currentCountry: CountryDataType | null) => void
) {
	const countriesRef = useRef<CountryDataType[]>(countriesData);
	const [countryInput, setCountryInput] = useState<string>("");
	const [currentCountry, setCurrentCountry] = useState<CountryDataType | null>(
		null
	);

	useEffect(() => {
		initializeCountryData();
		initializeInputValues();
	}, []);

	const initializeCountryData = () => {
		const countryCodes = Object.keys(countryMaskRules);
		countriesRef.current = countriesRef.current.map((country) => {
			if (countryCodes.includes(country.code)) {
				const rules = countryMaskRules[country.code];
				return { ...country, ...rules };
			}
			return country;
		});
	};

	const handleApplyMask = (
		value: string,
		country: CountryDataType | null
	): string => {
		let maskedValue = value;
		if (country?.mask) {
			maskedValue = applyMask(maskedValue, country.mask);
		} else if (country?.maskByLengthRule) {
			maskedValue = applyMask(
				maskedValue,
				country.maskByLengthRule(maskedValue.length)
			);
		}
		return maskedValue;
	};

	const initializeInputValues = () => {
		if (!phoneNumber) return;
		const foundCountry = countriesRef.current.find((country) =>
			phoneNumber.startsWith(normalizeDigitsOnly(country.phone))
		);
		if (!foundCountry) {
			setCountryInput(phoneNumber);
			return;
		}
		setCurrentCountry(foundCountry);
		let inputValue = phoneNumber.slice(
			normalizeDigitsOnly(foundCountry.phone).length
		);
		inputValue = handleApplyMask(inputValue, foundCountry);
		setCountryInput(inputValue);
	};

	const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const value = normalizeDigitsOnly(event.target.value);
		onPhoneChange(value, currentCountry);
		const newInputValue = handleApplyMask(value, currentCountry);
		setCountryInput(newInputValue);
	};

	const handleSelectChange = (event: SelectChangeEvent<string>): void => {
		const { value } = event.target;
		const foundCountry = countriesRef.current.find(
			(country) => country.code === value
		);
		setCurrentCountry(foundCountry || null);
		setCountryInput("");
		onPhoneChange("", foundCountry || null);
	};

	return {
		countriesRef,
		countryInput,
		currentCountry,
		handlePhoneChange,
		handleSelectChange,
	};
}

import React from "react";
import { Font, G, Rect, StyleSheet, Svg, Text } from "@react-pdf/renderer";
import JSBarcode from "jsbarcode";

const merge = (old, replaceObj) => ({ ...old, ...replaceObj });

function getEncodingHeight(options) {
	return options.height + options.marginTop + options.marginBottom;
}

function getBarcodePadding(textWidth, barcodeWidth, options) {
	if (options.displayValue && barcodeWidth < textWidth) {
		if (options.textAlign == "center") {
			return Math.floor((textWidth - barcodeWidth) / 2);
		} else if (options.textAlign == "left") {
			return 0;
		} else if (options.textAlign == "right") {
			return Math.floor(textWidth - barcodeWidth);
		}
	}
	return 0;
}

function calculateEncodingAttributes(encodings, barcodeOptions) {
	for (const encoding of encodings) {
		let options = merge(barcodeOptions, encoding.options);

		let textWidth = 20;

		let barcodeWidth = encoding.data.length * options.width;
		encoding.width = Math.ceil(Math.max(textWidth, barcodeWidth));

		encoding.height = getEncodingHeight(options);

		encoding.barcodePadding = getBarcodePadding(
			textWidth,
			barcodeWidth,
			options
		);
	}
}

function getTotalWidthOfEncodings(encodings) {
	let totalWidth = 0;
	for (const encoding of encodings) {
		totalWidth += encoding.width;
	}
	return totalWidth;
}

function getMaximumHeightOfEncodings(encodings) {
	let maxHeight = 0;
	for (const encoding of encodings) {
		if (encoding.height > maxHeight) {
			maxHeight = encoding.height;
		}
	}
	return maxHeight;
}

function Background(props) {
	const { width, height, color } = props;

	return (
		<Rect x={0} y={0} width={width} height={height} style={{ fill: color }} />
	);
}

function BarcodeChunk(props) {
	const { binary, padding, options } = props;

	// Creates the barcode out of the encoded binary
	let yFrom;
	if (options.textPosition == "top") {
		yFrom = options.fontSize + options.textMargin;
	} else {
		yFrom = 0;
	}

	let barWidth = 0;
	let x = 0;
	let bars = [];
	for (let b = 0; b < binary.length; b++) {
		x = b * options.width + padding;

		if (binary[b] === "1") {
			barWidth++;
		} else if (barWidth > 0) {
			bars.push({
				x: x - options.width * barWidth,
				y: yFrom,
				width: options.width * barWidth,
				height: options.height,
			});
			barWidth = 0;
		}
	}

	// Last draw is needed since the barcode ends with 1
	if (barWidth > 0) {
		bars.push({
			x: x - options.width * (barWidth - 1),
			y: yFrom,
			width: options.width * barWidth,
			height: options.height,
		});
	}

	return bars.map((bar) => {
		return (
			<Rect
				key={bar.x}
				x={bar.x}
				y={bar.y}
				width={bar.width}
				height={bar.height}
			/>
		);
	});
}

function BarcodeText(props) {
	const { text, width, padding, options } = props;

	// Draw the text if displayValue is set
	if (options.displayValue) {
		let x, y, textAnchor;

		if (options.textPosition == "top") {
			y = options.fontSize - options.textMargin + 8;
		} else {
			y = options.height + options.textMargin + options.fontSize;
		}

		// Draw the text in the correct X depending on the textAlign option
		if (options.textAlign == "left" || padding > 0) {
			x = 0;
			textAnchor = "start";
		} else if (options.textAlign == "right") {
			x = width - 1;
			textAnchor = "end";
		}
		// In all other cases, center the text
		else {
			x = width / 2;
			textAnchor = "middle";
		}

		return (
			<Text
				style={styles.tag}
				x={x}
				y={y}
				textAnchor={textAnchor}
				fill={options.lineColor}
			>
				{text}
			</Text>
		);
	} else {
		return null;
	}
}

export default function Barcode({ value, options }) {
	let barcode = {};

	JSBarcode(barcode, value, options);
	let encodings = barcode.encodings;

	const defaults = {
		width: 2,
		height: 100,
		displayValue: true,
		text: "",
		textAlign: "center",
		textPosition: "bottom",
		textMargin: 2,
		background: "#ffffff",
		lineColor: "#000000",
		marginTop: 10,
		marginBottom: 10,
		marginLeft: 0,
		marginRight: 2,
	};
	const mergedOptions = merge(defaults, options);

	calculateEncodingAttributes(encodings, mergedOptions);
	const totalWidth = getTotalWidthOfEncodings(encodings);
	const maxHeight = getMaximumHeightOfEncodings(encodings);
	const width =
		totalWidth + mergedOptions.marginLeft + mergedOptions.marginRight;

	let xs = [mergedOptions.marginLeft];
	encodings.forEach((e) => xs.push(xs[xs.length - 1] + e.width));

	return (
		<Svg
			x={0}
			y={0}
			width={width - 9}
			height={maxHeight}
			viewBox={`0 0 ${width - 10} ${maxHeight + 10}`}
			originX={0}
			originY={0}
		>
			{options.background && (
				<Background
					width={width}
					height={maxHeight}
					color={options.background}
				/>
			)}
			{encodings.map((encoding, i) => {
				const encodingOptions = merge(options, encoding.options);
				return (
					<G
						key={encoding.text}
						x={xs[i]}
						y={encodingOptions.marginTop}
						fill={encodingOptions.lineColor}
					>
						<BarcodeChunk
							binary={encoding.data}
							padding={encoding.barcodePadding}
							options={encodingOptions}
						/>
						<BarcodeText
							text={encoding.text}
							width={encoding.width}
							padding={encoding.barcodePadding}
							options={encodingOptions}
						/>
					</G>
				);
			})}
		</Svg>
	);
}

Font.register({
	family: "Arial",
	fonts: [
		{
			src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/arial@1.0.4/Arial.ttf",
		},
		{
			src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/arial-bold@1.0.4/Arial Bold.ttf",
			fontWeight: "bold",
		},
	],
});

const styles = StyleSheet.create({
	tag: { fontFamily: "Arial", fontWeight: "bold", fontSize: "11pt" },
});

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import CustomTypography from "./CustomTypography";
import { i18n } from "../translate/i18n";
import LoadingButtonIcon from "../components/LoadingButtonIcon";
import { ActionButton } from "../domain/types/ActionButton";

type DialogInfo = { title: string; subtitle?: string };
type DialogStyles = {
	titleVariant: "title" | "secondaryTitle";
};

type Props = {
	children?: ReactNode;
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	dialogInfo: DialogInfo;
	actionButton: ActionButton;
	dialogStyles?: DialogStyles;
	closeCallback?: Function;
};

export default function CustomDialog({
	children,
	isOpen,
	setIsOpen,
	dialogInfo,
	actionButton,
	dialogStyles = {
		titleVariant: "secondaryTitle",
	},
	closeCallback,
}: Props) {
	const handleOnClose = () => {
		setIsOpen(false);
		if (closeCallback) closeCallback();
	};

	return (
		<Dialog open={isOpen} onClose={handleOnClose}>
			<DialogTitle sx={{ p: 3, pb: 2 }}>
				<CustomTypography
					variant={dialogStyles.titleVariant}
					marginBottom={0.5}
				>
					{dialogInfo.title}
				</CustomTypography>
				{dialogInfo.subtitle && (
					<CustomTypography variant="default">
						{dialogInfo.subtitle}
					</CustomTypography>
				)}
			</DialogTitle>
			{children && (
				<DialogContent sx={{ px: 3, pb: 2 }}>{children}</DialogContent>
			)}
			<DialogActions sx={{ px: 3, pb: 3 }}>
				<Button variant="contained" color="light" onClick={handleOnClose}>
					{i18n.t(`buttons.General.BtnCancel`).toString()}
				</Button>
				<Button
					variant={actionButton.variant}
					color={actionButton.color}
					onClick={() => actionButton.onClick()}
					disabled={actionButton.isDisabled || actionButton.isLoading}
				>
					{actionButton.isLoading ? <LoadingButtonIcon /> : actionButton.text}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { roleAccessFinancial } from "../../../infrastructure/utils/RoleAccessFinancial";
export class Index extends Component {
	constructor(props) {
		super(props);
		this.oldLori = this.oldLori.bind(this);
		this.newLori = this.newLori.bind(this);
		this.baseURL = process.env.REACT_APP_API_ENDPOINT;

		//Admin = 1                     //NeomodeAdmin
		//Retailer = 2                  //Varejista
		//Seller = 3                    //Lojista
		//Marketing = 4                 //Marketing
		//SalesManagement = 5           //Gestão de vendas (somente leitura)
		//SalesManagementComplete = 6   //Gestão de vendas (acesso completo)
		//Marketplace = 7               //Marketplace
		//MarketplaceRetailer = 8       //Varejista de shopping
		this.state = {
			items: [
				{
					name: "dashboard",
					label: "Dashboard",
					icon: "dashboard",
					/* link: this.baseURL + 'admin/', */
					link: "/dashboard",
					rolesAccess: [2, 3, 4, 5, 6, 7, 8],
				},
				{
					name: "orders",
					label: "Pedidos",
					icon: "receipt",
					// link: localStorage.getItem('role') === '8' ? '/orders/1' : this.baseURL + 'admin/ordermanagement/',
					link: "/orders/1",
					rolesAccess: [2, 3, 5, 6, 7, 8],
				},
				{
					name: "public",
					label: "Público",
					icon: "people_alt",
					rolesAccess: [2, 4, 7],
					subItems: [
						{
							name: "buyers",
							label: "Clientes",
							link: this.baseURL + "admin/buyers",
							rolesAccess: [2, 4, 7],
						},
						// {
						//   name: 'messages',
						//   label: 'Push notifications',
						//   link: this.baseURL + 'admin/pushnotifications',
						//   rolesAccess: [2, 4, 7]
						// },
						{
							name: "feedbacks",
							label: "Reclamações",
							link: this.baseURL + "admin/feedbacks",
							rolesAccess: [2, 4, 7],
						},
					],
				},
				{
					name: "catalog",
					label: "Catálogo",
					icon: "all_inbox",
					rolesAccess: [2, 3, 4, 7, 8],
					link: "#",
					subItems: [
						{
							name: "products",
							label: "Produtos",
							link: "/products/1",
							rolesAccess: [2, 7, 8],
							/* sub: [
                {
                  name: 'sku',
                  label: 'SKUs',
                  link: '/sku/1',
                  rolesAccess: [2, 7, 8]
                },
              ] */
						},
						{
							name: "inventory",
							label: "Estoques",
							link: "/catalog/management/inventory/1",
							rolesAccess: [2, 3, 8],
						},
						{
							name: "categories",
							label: "Categorias",
							link: "/catalog/management/categories",
							rolesAccess: [2, 7, 8],
						},
						{
							name: "collections",
							label: "Coleções",
							link: "/collections/1",
							//link: this.baseURL + 'admin/v2/redirectto?url=tags',
							rolesAccess: [2, 7],
						},
						{
							name: "benefits",
							label: "Benefícios",
							rolesAccess: [2, 4, 7],
							sub: [
								{
									name: "coupons",
									label: "Cupons de desconto",
									link: "/vouchers/1",
									// link: this.baseURL + 'admin/v2/redirectto?url=vouchers',
									rolesAccess: [2, 4, 7],
								},
								{
									name: "promotions",
									label: "Promoções",
									link: "/promotions",
									rolesAccess: [2, 4, 7],
								},
							],
						},
						{
							name: "reviews",
							label: "Avaliações de produtos",
							link: this.baseURL + "admin/evaluationresponses",
							rolesAccess: [2, 7],
						},
						{
							name: "import",
							label: "Importação",
							link: "/import",
							rolesAccess: [2, 3, 7, 8],
						},
						// Export screen commented because it is not finished
						// {
						// 	name: "export",
						// 	label: "Exportação",
						// 	link: "/export/1",
						// 	rolesAccess: [2, 3, 7, 8],
						// },
						{
							name: "matcher",
							label: "Mapeamento de Catálogo",
							link: "/matcher",
							//link: this.baseURL + 'admin/v2/redirectto?url=tags',
							rolesAccess:
								localStorage.getItem("UseCatalogProcessor") === "true" &&
								localStorage.getItem("UseCatalogMatcher") === "true"
									? [2, 7, 8]
									: [],
						},
					],
				},
				{
					name: "stores",
					label: "Lojas",
					icon: "store",
					rolesAccess: [2, 7, 3],
					subItems: [
						{
							name: "storesList",
							label: "Cadastro de Lojas",
							link: "/stores/1",
							rolesAccess: [2, 7],
						},
						{
							name: "salespeople",
							label: "Vendedores",
							link: "/salespeople/1",
							rolesAccess: [2, 7, 3],
						},
						{
							name: "map",
							label: "Mapa de lojas",
							link: this.baseURL + "admin/stores",
							rolesAccess: [2, 7],
						},
					],
				},

				{
					name: "gestao",
					label: "Gestão de Canal",
					icon: "devices_other",
					rolesAccess: [2, 4, 7],
					subItems: [
						// {
						//   name: 'appEditor',
						//   label: 'Editor do App',
						//   link: '/channel-management/app-editor',
						//   rolesAccess: [2, 7]
						// },
						{
							name: "app",
							label: "Aplicativo",
							rolesAccess: [2, 4, 7],
							sub: [
								{
									name: "carousel-banner",
									label: "Banners de Carrossel",
									link: "/channel-management/app/carousel-banner",
									rolesAccess: [2, 4, 7],
								},
								{
									name: "carousel-banner",
									label: "Banners de Corpo",
									link: "/channel-management/app/body-banner",
									rolesAccess: [2, 4, 7],
								},
								{
									name: "tutorial",
									label: "Tutoriais",
									link: "/channel-management/app/tutorial",
									rolesAccess: [2, 4, 7],
								},
								{
									name: "showcase",
									label: "Vitrine",
									link: this.baseURL + "admin/showcase",
									rolesAccess: [2, 4, 7],
								},
							],
						},
						{
							name: "conteudo",
							label: "Conteúdo",
							rolesAccess: [2, 4, 7],
							sub: [
								{
									name: "termsofuse",
									label: "Termo de Uso",
									link: "/channel-management/content/terms-of-use",
									rolesAccess: [2, 4, 7],
								},
								{
									name: "privacypolicies",
									label: "Política de Privacidade",
									link: "/channel-management/content/privacy-policy",
									rolesAccess: [2, 4, 7],
								},
								{
									name: "promotionpolicies",
									label: "Regulamento",
									link: "/channel-management/content/promotion-policy",
									rolesAccess:
										localStorage.getItem("EnableUserPromotion") === "true"
											? [2, 4, 7]
											: [],
								},
								{
									name: "faq",
									label: "Perguntas Frequentes (FAQ)",
									link: "/channel-management/content/faq",
									rolesAccess: [2, 4, 7],
								},
							],
						},
					],
				},
				{
					name: "relatorios",
					label: "Relátorios",
					icon: "timeline",
					link: this.baseURL + "admin/retailerreports",
					rolesAccess: [2, 7],
				},
				{
					name: "financial",
					label: "Financeiro",
					icon: "request_quote",
					rolesAccess: roleAccessFinancial(
						localStorage.getItem("usePagarMeDashboard"),
						localStorage.getItem("usePagarMeDashboardForRetailer"),
						localStorage.getItem("role")
					),
					link: "/financial/payables",
				},
				{
					name: "status",
					label: "Status",
					icon: "show_chart",
					rolesAccess: [8],
					link: "/status",
				},
			],
		};
	}

	oldLori(params, style) {
		return (
			<a href={params.link} className={`${style} nav-link`}>
				{params.icon && (
					<i className="material-icons-outlined">{params.icon}</i>
				)}
				{params.label}
			</a>
		);
	}
	newLori(params, style) {
		return (
			<Link
				to={params.link}
				className={`${style} nav-link`}
				data-toggle={params.subItems && "dropdown"}
				aria-expanded={params.subItems && "true"}
			>
				{params.icon && (
					<i className="material-icons-outlined">{params.icon}</i>
				)}
				{params.label}
			</Link>
		);
	}

	render() {
		return (
			<nav className="navbar navbar-light navbar-expand-lg horizontal-menu header-navbar">
				<div className="container">
					<div
						className="collapse navbar-collapse"
						id="conteudoNavbarSuportado"
					>
						<ul className="menu navbar-nav">
							{this.state.items.map((item) => (
								<>
									{item.rolesAccess.findIndex(
										(element) => element == localStorage.getItem("role")
									) != -1 && (
										<li
											key={item.name}
											className={
												item.subItems ? "nav-item dropdown" : "nav-item"
											}
											data-menu="dropdown"
											aria-expanded="true"
										>
											{item.subItems ? (
												<>
													{this.newLori(
														item,
														item.subItems && "dropdown-toggle"
													)}
													<ul className="submenu">
														{item.subItems.map((subItem) => (
															<>
																{subItem.rolesAccess.findIndex(
																	(element) =>
																		element == localStorage.getItem("role")
																) != -1 && (
																	<li
																		key={subItem.name}
																		className="dropdown-submenu m-0 p-0"
																	>
																		{subItem.sub ? (
																			<>
																				<Link
																					className="dropright-menu second-menu nav-link w-100"
																					to="/#"
																				>
																					{subItem.label}
																					<i className="material-icons">
																						navigate_next
																					</i>
																				</Link>
																				<ul className="menu-submenu">
																					{subItem.sub.map((sub) => (
																						<>
																							{sub.rolesAccess.findIndex(
																								(element) =>
																									element ==
																									localStorage.getItem("role")
																							) != -1 && (
																								<li key={sub.name}>
																									{sub.link.includes(
																										"lori.neomode"
																									) ||
																									sub.link.includes(
																										"lori-hlog.neomode"
																									)
																										? this.oldLori(sub, "")
																										: this.newLori(sub, "")}
																								</li>
																							)}
																						</>
																					))}
																				</ul>
																			</>
																		) : subItem.link.includes("lori.neomode") ||
																		  subItem.link.includes(
																				"lori-hlog.neomode"
																		  ) ? (
																			this.oldLori(subItem, "dropright-menu")
																		) : (
																			this.newLori(subItem, "dropright-menu")
																		)}
																	</li>
																)}
															</>
														))}
													</ul>
												</>
											) : item.link.includes("lori.neomode") ||
											  item.link.includes("lori-hlog.neomode") ? (
												this.oldLori(item, "")
											) : (
												this.newLori(item, "")
											)}
										</li>
									)}
								</>
							))}
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default Index;

import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
	family: "Arial",
	fonts: [
		{
			src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/arial@1.0.4/Arial.ttf",
		},
		{
			src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/arial-bold@1.0.4/Arial Bold.ttf",
			fontWeight: "bold",
		},
	],
});

export const styles = StyleSheet.create({
	BoldText: { fontWeight: "bold" },
	CourierLabel: {
		backgroundColor: "white",
		border: "1px solid black",
		width: "106.36mm",
		fontFamily: "Arial",
	},
	// Header styles
	Header: { paddingHorizontal: "5mm", paddingVertical: "2.5mm" },
	LogoWrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	CourierIcon: { marginTop: "1mm", marginRight: "3mm", height: "4mm" },
	CompanyLogo: { height: "25mm", maxWidth: "25mm" },
	ServiceIcon: { height: "20mm", maxWidth: "20mm" },
	HeaderInfo: {
		marginTop: "1.5mm",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		fontSize: "9pt",
	},
	MiddleHeaderInfo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	HeaderBarcode: { marginLeft: "-5mm" },
	SignatureInputs: { marginTop: "1mm", fontSize: "9pt" },
	SignatureAndDocumentRow: {
		display: "flex",
		flexDirection: "row",
		gap: "2mm",
		marginTop: "2mm",
	},
	// Recipient styles
	RecipientWrapper: {
		borderTop: "1px solid black",
		borderBottom: "1px solid black",
		fontSize: "11pt",
	},
	RecipientHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	RecipientTitle: {
		backgroundColor: "#000000",
		color: "#FFFFFF",
		width: "34mm",
		fontWeight: "bold",
		paddingLeft: "3mm",
		marginBottom: "2mm",
	},
	RecipientInfo: {
		paddingHorizontal: "5mm",
		overflow: "hidden",
	},
	RecipientBarcode: {
		marginTop: "1.5mm",
		marginLeft: "-1mm",
		marginBottom: "-6mm",
	},
	// Sender styles
	SenderWrapper: {
		paddingVertical: "1.5mm",
		paddingLeft: "3mm",
		fontSize: "10pt",
	},
});

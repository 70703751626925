import BaseApi from "./BaseApi";
import { HttpMethod } from "../../domain/enums/HttpMethodType";
import { OrdersDto } from "../../domain/dtos/smOrders/OrdersDto";

interface ISMCommerceManagement {
	getOrders: (params: string) => Promise<OrdersDto>;
}

class SMCommerceManagement extends BaseApi implements ISMCommerceManagement {
	async getOrders(params: string): Promise<OrdersDto> {
		let resp = await this.request<OrdersDto>(
			`orders?${params}`,
			HttpMethod.Get
		);

		if (!this.isResponseSuccessful(resp.status)) {
			throw new Error("Could not get orders");
		}

		return resp.data;
	}
}

export const smCommerceManagementApi: ISMCommerceManagement =
	new SMCommerceManagement("sm/management/commerce/");

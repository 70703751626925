import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import CustomInput from "../../MUICustomComponents/CustomInput";
import { i18n } from "../../translate/i18n";
import LoadingButtonIcon from "../LoadingButtonIcon";
import { InputMasks } from "../../infrastructure/utils/InputMasks";
import { normalizeDigitsOnly } from "../../infrastructure/utils/StringUtils";
import { salesApi } from "../../infrastructure/api/SalesApi";
import { ZipCodeDto } from "../../domain/dtos/SalesApi/ZipCodeDto";

type Props = {
	inputName: string;
	zipCode: string | null;
	isLoadingZipCode: boolean;
	setIsLoadingZipCode: Dispatch<SetStateAction<boolean>>;
	handleZipCodeChange: (
		value: string,
		zipCodeResponse?: ZipCodeDto | false
	) => void;
	formErrorText?: string[];
};

export default function ZipCodeInput({
	inputName,
	zipCode,
	isLoadingZipCode,
	setIsLoadingZipCode,
	handleZipCodeChange,
	formErrorText = [],
}: Props) {
	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (value.length < normalizeDigitsOnly(InputMasks.ZipCode).length) {
			handleZipCodeChange(value);
			return;
		}
		setIsLoadingZipCode(true);
		getZipCode(value);
	};

	const getZipCode = async (zipCode: string) => {
		try {
			const response = await salesApi.getZipCode(zipCode);
			handleZipCodeChange(zipCode, response);
		} catch {
			handleZipCodeChange(zipCode, false);
		}
		setIsLoadingZipCode(false);
	};

	return (
		<CustomInput
			name={inputName}
			title={i18n.t("input.zipCodeLabel")}
			applyInputMask={InputMasks.ZipCode}
			value={zipCode}
			onChange={onChange}
			endAdornment={isLoadingZipCode && <LoadingButtonIcon />}
			disabled={isLoadingZipCode}
			error={formErrorText.length > 0}
			helperText={formErrorText}
		/>
	);
}

import React from "react";
import CustomTypography from "../../../../../MUICustomComponents/CustomTypography";
import { i18n } from "../../../../../translate/i18n";
import { Link } from "react-router-dom";
import {
	Box,
	Button,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import ConditionListService from "./ConditionListService";
import CustomIcon from "../../../../../MUICustomComponents/CustomIcon";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { formatCurrency } from "../../../../../infrastructure/utils/FormatUtils";
import LoadingButtonIcon from "../../../../../components/LoadingButtonIcon";
import CustomDialog from "../../../../../MUICustomComponents/CustomDialog";
import CustomSkeleton from "../../../../../MUICustomComponents/CustomSkeleton";
import EmptyComponent from "../../../../../components/EmptyComponent/EmptyComponent";
import { percentageMultiplication } from "../../../../../infrastructure/utils/MathOperations";

export default function ConditionList() {
	const {
		conditions,
		conditionToDelete,
		isDeletingRequest,
		isDeletionOpen,
		isLoading,
		setIsDeletionOpen,
		handleDeleteButton,
		deleteCondition,
	} = ConditionListService();

	const renderTable = (): JSX.Element => {
		return (
			<Table aria-label="Commercial conditions table">
				<TableHead>
					<TableRow>
						<TableCell>
							{i18n.t("paymentConfig.Condition").toString()}
						</TableCell>
						<TableCell align="center">
							{i18n.t("paymentConfig.TableClampPercentage").toString()}
						</TableCell>
						<TableCell align="center">
							{i18n.t("paymentConfig.TableMinInstallmentValue").toString()}
						</TableCell>
						<TableCell align="center">
							{i18n.t("paymentConfig.ConditionIsActive").toString()}
						</TableCell>
						<TableCell align="center" />
						<TableCell align="right">
							{i18n.t("paymentConfig.Actions").toString()}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{conditions.map((condition) => (
						<TableRow
							key={condition.id}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell>{condition.slug}</TableCell>
							<TableCell align="center">
								{percentageMultiplication(condition.clampPercentage.toString())}
								%
							</TableCell>
							<TableCell align="center">
								{formatCurrency(condition.minInstallmentValue)}
							</TableCell>
							<TableCell align="center">
								{i18n
									.t(
										`paymentConfig.IsActive${
											condition.isActive ? "True" : "False"
										}`
									)
									.toString()}
							</TableCell>
							<TableCell sx={{ fontWeight: "bold" }} align="center">
								{condition.isDefault &&
									i18n.t("paymentConfig.IsDefaultCondition").toString()}
							</TableCell>
							<TableCell align="right">
								<Link
									to={
										isDeletingRequest
											? "#"
											: `/configurations/payment/commercial-conditions/${condition.id}`
									}
								>
									<IconButton disabled={isDeletingRequest}>
										<CustomIcon variant="default" icon={<EditOutlined />} />
									</IconButton>
								</Link>
								<IconButton
									color="danger"
									onClick={() => handleDeleteButton(condition.id!)}
									disabled={condition.isDefault || isDeletingRequest}
								>
									{isDeletingRequest && conditionToDelete === condition.id ? (
										<LoadingButtonIcon />
									) : (
										<CustomIcon variant="default" icon={<DeleteOutline />} />
									)}
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	};

	const contentToBeRendered = () => {
		if (isLoading) return <CustomSkeleton quantity={6} height={30} />;
		if (conditions.length <= 0) {
			return (
				<EmptyComponent
					imageSrc={"/images/empty_inventory.svg"}
					title={i18n.t("paymentConfig.EmptyConditionListTitle")}
					message={i18n.t("paymentConfig.EmptyConditionListMessage")}
				/>
			);
		}
		return renderTable();
	};

	return (
		<div className="card">
			<Box p={4}>
				<Stack direction="row" justifyContent="space-between" pb={2}>
					<CustomTypography variant="title">
						{i18n.t("paymentConfig.CommercialConditions").toString()}
					</CustomTypography>
					<Link
						to={{
							pathname: "/configurations/payment/commercial-conditions/new",
							state: { isDefaultConditionCreation: conditions.length <= 0 },
						}}
					>
						<Button variant="contained" color="grey80">
							{i18n.t("paymentConfig.NewCondition").toString()}
						</Button>
					</Link>
				</Stack>
				{contentToBeRendered()}
			</Box>
			<CustomDialog
				isOpen={isDeletionOpen}
				setIsOpen={setIsDeletionOpen}
				dialogInfo={{
					title: i18n.t("paymentConfig.ConfirmDeletionExclusion"),
					subtitle: i18n.t("paymentConfig.DeletionWarning"),
				}}
				actionButton={{
					text: i18n.t(`buttons.General.BtnDelete`),
					variant: "outlined",
					color: "danger",
					onClick: () => deleteCondition(conditionToDelete!),
					isDisabled: isDeletingRequest,
					isLoading: isDeletingRequest,
				}}
			/>
		</div>
	);
}

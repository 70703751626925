import React, { useEffect, useState } from "react";
import { getToken } from "../../../../push-notification";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { i18n } from "../../../../translate/i18n";
import { Chip } from "@mui/material";

function Settings() {
	const [baseURL, setBaseURL] = useState("");
	const [isTokenFound, setTokenFound] = useState(false);

	getToken(setTokenFound);

	useEffect(() => {
		setBaseURL(process.env.REACT_APP_API_ENDPOINT);
		//console.log(isTokenFound)
		const element = document?.querySelector(".HW_bottom ");
	}, [isTokenFound]);

	const logout = () => {
		localStorage.clear();
		window.location.href = "/login";
	};

	return (
		<>
			<div className="dropdown settings-dropdown">
				<a
					className="nav-link nav-link-label d-flex pb-2"
					data-toggle="dropdown"
				>
					<i className="material-icons-outlined settings-dropdown-icon">
						settings
					</i>
				</a>
				<div className="dropdown-menu dropdown-menu-right settings-dropdown-menu">
					<img
						src="/images/dropdown-triangle.svg"
						className="settings-dropdown-triangle"
					/>
					{(localStorage.getItem("role") === "1" ||
						localStorage.getItem("role") === "2" ||
						localStorage.getItem("role") === "7") && (
						<>
							<h1 className="dropdown-header settings-dropdown-header">
								Preferências
							</h1>
							<div className="dropdown-divider settings-dropdown-divider"></div>
						</>
					)}
					{localStorage.getItem("role") === "2" && (
						<Link
							className="dropdown-item settings-dropdown-item"
							to="/configurations/payment"
							style={{ display: "flex", alignItems: "baseline" }}
						>
							{i18n.t("configurations.Payment")}
							<Chip
								label="novo"
								size="small"
								variant="outlined"
								color="success"
								sx={{ mx: 1 }}
							/>
						</Link>
					)}

					{(localStorage.getItem("role") === "2" ||
						localStorage.getItem("role") === "7") && (
						<>
							<a
								className="dropdown-item settings-dropdown-item"
								href={baseURL + "admin/retailerconfigurations"}
							>
								Configurações do Varejista
							</a>
							<a
								className="dropdown-item settings-dropdown-item"
								href={baseURL + "admin/configurations"}
							>
								Integrações
							</a>
							<a
								className="dropdown-item settings-dropdown-item"
								href={baseURL + "admin/feedbacktypes"}
							>
								Tipos de Reclamação
							</a>
							<a
								className="dropdown-item settings-dropdown-item"
								href={baseURL + "admin/useraccesses"}
							>
								Acessos de Usuários
							</a>
							<a
								className="dropdown-item settings-dropdown-item"
								href={baseURL + "admin/evaluations"}
							>
								Questionários de Avaliação
							</a>
							<div className="dropdown-divider settings-dropdown-divider"></div>
						</>
					)}

					{localStorage.getItem("role") === "1" && (
						<>
							<Link
								className="dropdown-item settings-dropdown-item"
								to={"/retailerconfigurations"}
							>
								Configurações internas
							</Link>
							<div className="dropdown-divider settings-dropdown-divider"></div>
						</>
					)}

					<a
						className="dropdown-item settings-dropdown-item logout"
						onClick={() => logout()}
					>
						Sair da Conta
					</a>
				</div>
			</div>
		</>
	);
}

export default Settings;

import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import Barcode from "./Barcode";
import CourierIcon from "./assets/CourierIcon.png";
import EmptyShippingIcon from "./assets/EmptyShippingIcon.png";
import SedexIcon from "./assets/SedexIcon.png";
import PacIcon from "./assets/PacIcon.png";
import SedexeIcon from "./assets/SedexeIcon.png";
import { ShippingTypeIcon } from "./enums/ShippingTypeIcon";
import { CourierAddress, CourierObject } from "./CourierTypes";
import { formatZipCode } from "../../infrastructure/utils/FormatMask";
import { i18n } from "../../translate/i18n";
import { styles } from "./CourierStyles";

type CourierProps = {
	courierObject: CourierObject;
};

export default function CourierLabel({ courierObject }: CourierProps) {
	const companyLogo = localStorage.getItem("primaryLogo");

	const getShippingTypeIcon = (icon: string) => {
		icon = icon.toLowerCase();
		switch (icon) {
			case ShippingTypeIcon.Pac:
				return PacIcon;
			case ShippingTypeIcon.Sedex:
				return SedexIcon;
			case ShippingTypeIcon.Sedexe:
				return SedexeIcon;
			default:
				return EmptyShippingIcon;
		}
	};

	const showAddressInfo = (addressObject: CourierAddress) => {
		return (
			<>
				<Text>
					{addressObject.street ? `${addressObject.street}, ` : ""}
					{addressObject.number ? `${addressObject.number}` : ""}
				</Text>
				<Text>
					{addressObject.complement ? `${addressObject.complement}, ` : ""}
					{addressObject.reference ? `${addressObject.reference}, ` : ""}
					{addressObject.neighborhood ? `${addressObject.neighborhood}` : ""}
				</Text>
				<Text>
					<Text style={styles.BoldText}>
						{formatZipCode(addressObject.zipCode)}
					</Text>
					{` - ${addressObject.city}/${addressObject.state}`}
				</Text>
			</>
		);
	};

	return (
		<Document>
			<Page size="A4">
				<View style={styles.CourierLabel}>
					<View style={styles.Header}>
						<View style={styles.LogoWrapper}>
							{companyLogo ? (
								<Image style={styles.CompanyLogo} src={companyLogo} />
							) : (
								<View style={styles.CompanyLogo} />
							)}
							<Image
								style={styles.ServiceIcon}
								src={getShippingTypeIcon(courierObject.shippingType ?? "")}
							/>
						</View>
						<View style={styles.HeaderInfo}>
							<View>
								{courierObject.invoice && (
									<Text>
										{i18n.t("courierLabel.Invoice").toString()}:{" "}
										{courierObject.invoice}
									</Text>
								)}
								{courierObject.plp && (
									<Text>
										{i18n.t("courierLabel.Plp").toString()}: {courierObject.plp}
									</Text>
								)}
							</View>
							<View style={styles.MiddleHeaderInfo}>
								{courierObject.contract && (
									<Text>
										{i18n.t("courierLabel.Contract").toString()}:{" "}
										<Text style={styles.BoldText}>
											{courierObject.contract}
										</Text>
									</Text>
								)}
								{courierObject.shippingType && (
									<Text style={styles.BoldText}>
										{courierObject.shippingType.toUpperCase() ?? ""}
									</Text>
								)}
							</View>
							<View>
								<Text>{i18n.t("courierLabel.StaticVolume").toString()}</Text>
								{courierObject.weight > 0 && (
									<Text>
										{i18n.t("courierLabel.Weight").toString()}:{" "}
										<Text style={styles.BoldText}>{courierObject.weight}</Text>
									</Text>
								)}
							</View>
						</View>
						<View style={styles.HeaderBarcode}>
							<Barcode
								value={
									courierObject.trackingCode
										.replace("-", "")
										.replace(".", "") ?? ""
								}
								options={{
									format: "CODE128",
									background: "white",
									ean128: true,
									width: 1.49,
									height: 55,
									textPosition: "top",
									textMargin: 8,
								}}
							/>
						</View>
						<View style={styles.SignatureInputs}>
							<Text>
								{i18n.t("courierLabel.Receiver").toString()}:{" "}
								____________________________________________
							</Text>
							<View style={styles.SignatureAndDocumentRow}>
								<Text>
									{i18n.t("courierLabel.Signature").toString()}:{" "}
									________________
								</Text>
								<Text>
									{i18n.t("courierLabel.Document").toString()}:{" "}
									_________________
								</Text>
							</View>
						</View>
					</View>
					<View style={styles.RecipientWrapper}>
						<View style={styles.RecipientHeader}>
							<Text style={styles.RecipientTitle}>
								{i18n.t("courierLabel.Recipient").toString()}
							</Text>
							<Image style={styles.CourierIcon} src={CourierIcon} />
						</View>
						<View style={styles.RecipientInfo}>
							<View>
								<Text>{courierObject.recipientFullName}</Text>
								{showAddressInfo(courierObject.recipientAddress)}
							</View>
							<View style={styles.RecipientBarcode}>
								<Barcode
									value={courierObject.recipientAddress.zipCode
										.replace("-", "")
										.replace(".", "")}
									options={{
										format: "CODE128",
										background: "white",
										ean128: true,
										width: 1.37,
										height: 55,
										displayValue: false,
									}}
								/>
							</View>
						</View>
					</View>
					<View style={styles.SenderWrapper}>
						<Text>
							<Text style={styles.BoldText}>
								{i18n.t("courierLabel.Sender").toString()}:{" "}
							</Text>
							{courierObject.senderfullName}
						</Text>
						{showAddressInfo(courierObject.senderAddress)}
					</View>
				</View>
			</Page>
		</Document>
	);
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { API, APIV2, APIV1 } from "../../../../../API";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { buildURLWithParams } from "../../../../../infrastructure/utils/BuildURLWithParams";

export const VouchersProvider = createContext({});

export default function VouchersContext({ children }) {
	const params = new URL(document.location).searchParams;
	const pageParam = useParams().page;
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [disable, setDisable] = useState(true);
	const [coupon, setCoupon] = useState([]);
	const [editedStartDate, setEditedStartDate] = useState(false);
	const [editedEndDate, setEditedEndDate] = useState(false);
	const [requestCompleted, setRequestCompleted] = useState(false);
	const [page, setPage] = useState(pageParam || "1");
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [query, setQuery] = useState(params.get("query") || "");
	const [loadingExport, setLoadingExport] = useState(false);
	const [voucher, setVoucher] = useState({
		startDate: new Date(),
		endDate: new Date(),
		tipoDesconto: "real",
		code: "",
		minValue: "",
		maxValue: "",
		minQuantity: "",
		freeShipping: false,
		isApplyInTotalCart: false,
		hasNoFeeInstallments: false,
		discountPercentage: "",
		discountValue: "",
		documents: [],
		products: [],
		categories: [],
		categoriesId: [],
		states: [],
		cities: [],
		tags: [],
		sellers: [],
		sellerId: "",
		maxInstallmentsWithoutFee: "",
		oneByUser: "",
		firstUse: "",
		shippingType: "",
		paymentMethods: "",
		amountOrders: "",
		subTotalOrders: "",
		ticketAverage: "",
		discountTotalOrders: "",
		isNotApplyProductReprice: "",
		usageLimit: 0,
		discountApplyType: 3,
		isActive: true,
	});
	const [vouchersList, setVouchersList] = useState([]);
	const [maxCount, setMaxCount] = useState([]);

	useEffect(() => {
		localStorage.removeItem("PARAMS");
		setDisable(false);
	}, [voucher]);

	const getVouchersList = async () => {
		localStorage.removeItem("PARAMS");
		setVouchersList([]);
		try {
			let queryParams = {
				page: page,
				perPage: rowsPerPage,
				query: query,
			};

			const response = await APIV2.get(
				`management/marketing/vouchers?${buildURLWithParams(queryParams)}`
			);

			let newDate = new Date();
			let today = new Date(newDate.setHours(newDate.getHours()));
			response.data.vouchers.forEach((voucher) => {
				let newStartDate = new Date(voucher.startDate);
				let since = new Date(
					newStartDate.setHours(newStartDate.getHours() - 3)
				);

				let until = new Date(voucher.endDate);
				const data = {
					...voucher,
					status:
						since.toISOString() > today.toISOString()
							? "disabled"
							: until.toISOString() < today.toISOString()
							? "finish"
							: "actived",
				};
				setVouchersList((prevState) => [...prevState, data]);
			});
			setMaxCount(response.data.maxCount);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const getNewVouchers = async (id) => {
		id &&
			(await API.get(`api/vouchers/info?id=${id}`)
				.then((response) => {
					let newStartDate = new Date(response.data.startDate);
					let newEndDate = new Date(response.data.endDate);

					const data = {
						...response.data,
						startDate: newStartDate.toISOString(),
						endDate: newEndDate.toISOString(),
						isNotApplyProductReprice: response.data.isNotApplyProductReprice
							? false
							: true,
					};

					setVoucher(data);
					setLoading(false);
					setDisable(false);
					setRequestCompleted(true);
				})
				.catch((error) => {
					setLoading(false);
					setDisable(false);
					console.log(error);
				}));
	};

	const handleVoucherHour = (date) => {
		const newDate = new Date(date);
		newDate.setHours(newDate.getHours() - 3);
		return newDate;
	};

	const createCupom = (formVoucher) => {
		setDisable(true);
		let newStartDate = new Date(formVoucher.startDate);
		let newEndDate = new Date(formVoucher.endDate);
		const newVoucher = {
			...formVoucher,
			startDate: newStartDate.toISOString(),
			endDate: newEndDate.toISOString(),
			isNotApplyProductReprice: formVoucher.isNotApplyProductReprice
				? false
				: true,
		};

		API.post(`api/vouchers/save`, newVoucher)
			.then(async (response) => {
				toast.success("Voucher criado com sucesso! 😃");
				history.push("/vouchers/1");
				setDisable(false);
			})
			.catch((error) => {
				setDisable(false);
				console.log(error);
				if (error.response.data) {
					toast.error(error.response.data.Message);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const updateCupom = (formVoucher) => {
		setDisable(true);
		let newStartDate = new Date(formVoucher.startDate);
		let newEndDate = new Date(formVoucher.endDate);
		const newVoucher = {
			...formVoucher,
			startDate: newStartDate.toISOString(),
			endDate: newEndDate.toISOString(),
			isNotApplyProductReprice: voucher.isNotApplyProductReprice ? false : true,
		};

		API.post(`api/vouchers/update`, newVoucher)
			.then(async (response) => {
				toast.success("Voucher editado com sucesso! 😃");
				setDisable(false);
				history.push("/vouchers/1");
			})
			.catch((error) => {
				console.log(error);
				setDisable(false);
				if (error.response.data) {
					let date = new Date(error.response.data.CreateDate);
					const createDate = new Date(date.setHours(date.getHours()));
					toast.error(
						`Data de início deve conter horário mínimo ${createDate.toLocaleTimeString()}`
					);
				} else {
					toast.error(
						"Parece que tivemos um erro... Tente novamente mais tarde."
					);
				}
			});
	};

	const handleExportVouchers = async () => {
		setLoadingExport(true);

		await APIV1.get(
			`/insights/export/vouchers?clientId=${localStorage.getItem(
				"client_id"
			)}&query=${query}`
		)
			.then((response) => {
				const exportInterval = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (
						resp.data.runtimeStatus !== "Running" &&
						resp.data.runtimeStatus !== "Pending"
					) {
						switch (resp.data.runtimeStatus) {
							case "Completed":
								setLoadingExport(false);
								clearInterval(exportInterval);
								window.open(resp.data.output, "_blank");
								break;
							case "Failed":
							default:
								clearInterval(exportInterval);
								break;
						}
					}
				}, 4000);
			})
			.catch(() => {
				setLoadingExport(false);
			});
	};

	return (
		<VouchersProvider.Provider
			value={{
				loading,
				disable,
				coupon,
				setCoupon,
				voucher,
				setVoucher,
				setLoading,
				getNewVouchers,
				createCupom,
				updateCupom,
				handleVoucherHour,
				editedStartDate,
				setEditedStartDate,
				editedEndDate,
				setEditedEndDate,
				requestCompleted,
				getVouchersList,
				vouchersList,
				maxCount,
				page,
				setPage,
				rowsPerPage,
				setRowsPerPage,
				query,
				setQuery,
				handleExportVouchers,
				setLoadingExport,
				loadingExport,
			}}
		>
			{" "}
			{children}{" "}
		</VouchersProvider.Provider>
	);
}

export const useVouchers = () => useContext(VouchersProvider);

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { APIV1, APIV2 } from "../../API";
import List from "../../components/List/List";
import { toast } from "react-toastify";
import Empty from "../../components/Empty";
import ContentLoader from "react-content-loader";
import { useOrders } from "./providers/OrdersContext";
import FilterOrdersModal from "./components/FilterOrdersModal";
import axios from "axios";
import { buildURLWithParams } from "../../infrastructure/utils/BuildURLWithParams";
import { DateType } from "../../domain/enums/DateType";
import {
	getInitialDateType,
	getSinceFilter,
} from "../../infrastructure/utils/Dates";
import OrderFiltersLayout from "../../components/orders/OrderFiltersLayout/OrderFiltersLayout";
import OrderTableColumns, {
	conditionalRowStyles,
	overwriteOrderStyles,
} from "../../components/OrderTableColumns/OrderTableColumns";
import { ListOrderMapper } from "../../domain/mappers/orders/ListOrderMapper";

export default function OrdersList(props) {
	const { openModal, loading, setLoading } = useOrders();

	const [page, setPage] = useState(props.page ? parseInt(props.page) : 1);
	const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage ?? 20);
	const [startDate, setStartDate] = useState(
		props.since ?? new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
	);
	const [endDate, setEndDate] = useState(
		props.until ?? new Date().toISOString()
	);
	const [status, setStatus] = useState(props.status ? props.status : "");
	const [isDelivery, setIsDelivery] = useState(props.isDelivery ?? null);
	const [searchData, setSearchData] = useState(props.query ? props.query : "");
	const [dateType, setDateType] = useState(DateType.Today);
	const [modality, setModality] = useState(
		props.isDelivery ? props.isDelivery : ""
	);
	const [load, setLoad] = useState(false);
	const [output, setOutput] = useState("");
	const history = useHistory();
	const [filtered, setFiltered] = useState([]);
	const skele = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	const [sellerCode, setSellerCode] = useState(props.sellerCode ?? null);
	const [currentSeller, setCurrentSeller] = useState(null);
	const [maxCount, setMaxCount] = useState([]);
	const [ordersList, setOrdersList] = useState([]);
	const [triggerRowsOrPageChange, setTriggerRowsOrPageChange] = useState(false);

	const updateFilters = (orderFilters) => {
		setLoading(true);
		const {
			sellerCode: newSellerCode,
			page: newPage,
			rowsPerPage: newRowsPerPage,
			since: newSince,
			until: newUntil,
			status: newStatus,
			isDelivery: newIsDelivery,
			search: newSearch,
			dateType: newDateType,
		} = orderFilters;
		setSellerCode(newSellerCode);
		setPage(newPage);
		setRowsPerPage(newRowsPerPage);
		setStartDate(newSince);
		setEndDate(newUntil);
		setStatus(newStatus);
		setIsDelivery(newIsDelivery);
		setSearchData(newSearch);
		setDateType(newDateType);
		getOrdersList(
			newSellerCode,
			newPage,
			newRowsPerPage,
			newSince,
			newUntil,
			newStatus,
			newIsDelivery,
			newSearch,
			newDateType
		);
	};

	useEffect(() => {
		const el = document.querySelector("#dropdownMenuButton");
		if (el) {
			el.addEventListener("click", function () {
				setDateType(DateType.CustomDate);
			});
		}
	}, [document.getElementById("dropdownMenuButton")]);

	useEffect(() => {
		let queryParams = {
			since: startDate,
			until: endDate,
			status: status,
			isDelivery: isDelivery,
			query: searchData,
			sellerCode: sellerCode,
		};
		history.push(`${page}?${buildURLWithParams(queryParams)}`);
	}, [
		page,
		rowsPerPage,
		startDate,
		endDate,
		filtered,
		searchData,
		startDate,
		sellerCode,
	]);

	useEffect(() => {
		setLoading(true);
		const initialDateType = getInitialDateType(startDate);
		setDateType(initialDateType);
		getOrdersList(
			sellerCode,
			page,
			rowsPerPage,
			startDate,
			endDate,
			status,
			isDelivery,
			searchData,
			initialDateType
		);
	}, []);

	useEffect(() => {
		if (triggerRowsOrPageChange) {
			getOrdersList(
				sellerCode,
				page,
				rowsPerPage,
				startDate,
				endDate,
				status,
				isDelivery,
				searchData,
				dateType
			);
			setTriggerRowsOrPageChange(false);
		}
	}, [triggerRowsOrPageChange]);

	const getOrdersList = async (
		sellerCode,
		page,
		rowsPerPage,
		startDate,
		endDate,
		status,
		isDelivery,
		searchData,
		type
	) => {
		try {
			let queryParams = {
				page: page,
				perPage: rowsPerPage,
				since: startDate,
				until: endDate,
				status: status,
				isDelivery: isDelivery,
				query: searchData,
				role: localStorage.getItem("role"),
				timezone: new Date().getTimezoneOffset() / 60,
				sellerCode: sellerCode,
			};

			const response = await APIV2.get(
				`management/commerce/orders?${buildURLWithParams(queryParams)}`
			);
			const keepEmptyResult =
				searchData || sellerCode || status || isDelivery !== null;
			const orders = ListOrderMapper.legacyToListOrderModel(
				response.data.orders
			);

			if (orders.length >= 1 || keepEmptyResult) {
				setOrdersList(orders);
				setMaxCount(response.data.maxCount);
				setLoading(false);
				return;
			}

			if (type === DateType.Today) {
				setDateType(DateType.SevenDays);
				const sinceSevenDays = getSinceFilter(DateType.SevenDays);
				setStartDate(sinceSevenDays);
				getOrdersList(
					sellerCode,
					page,
					rowsPerPage,
					sinceSevenDays,
					endDate,
					status,
					isDelivery,
					searchData,
					DateType.SevenDays
				);
				return;
			}
			if (type === DateType.SevenDays) {
				setDateType(DateType.ThirtyDays);
				const sinceThirtyDays = getSinceFilter(DateType.ThirtyDays);
				setStartDate(sinceThirtyDays);
				getOrdersList(
					sellerCode,
					page,
					rowsPerPage,
					sinceThirtyDays,
					endDate,
					status,
					isDelivery,
					searchData,
					DateType.ThirtyDays
				);
				return;
			}

			setOrdersList(orders);
			setMaxCount(response.data.maxCount);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error("Parece que tivemos um erro... Tente novamente mais tarde.");
		}
	};

	const changePage = (e) => {
		setLoading(true);
		setPage(e);
		setTriggerRowsOrPageChange(true);
	};

	const changeRowsPerPage = (e) => {
		setLoading(true);
		setPage(1);
		setRowsPerPage(e);
		setTriggerRowsOrPageChange(true);
	};

	const getFilterSelect = (arrStatus, arrModality, doNewOrderRequest) => {
		setLoading(true);

		let filterStatus = arrStatus.filter((element) => {
			return element.checked === true;
		});
		let filterModality = arrModality.filter((element) => {
			return element.checked === true;
		});

		if (doNewOrderRequest) handleFilter(filterStatus, filterModality);
		else setLoading(false);
		let filterData = filterStatus.concat(filterModality);
		setFiltered(filterData);
	};

	const handleFilter = (dataStatus, dataModality) => {
		//function for handle a filter of status
		let onlyStatus = [];
		dataStatus.forEach((element) => {
			onlyStatus.push(element.value);
		});
		let filterStatus = String(onlyStatus);
		setStatus(filterStatus);
		//function for handle a filter of delivery
		setModality(dataModality);
		let onlyModality = [];
		dataModality.forEach((element) => {
			onlyModality.push(element.value);
		});
		let modality = String(onlyModality);
		let filterModality = modality === "ReciveHome" ? true : false;
		let returnModality = dataModality.length <= 0 ? null : filterModality;
		setIsDelivery(returnModality);
		let returnPage = 1;
		setPage(returnPage);
		getOrdersList(
			sellerCode,
			returnPage,
			rowsPerPage,
			startDate,
			endDate,
			filterStatus,
			returnModality,
			searchData,
			dateType
		);
	};

	const clearOneFilter = (data) => {
		setLoading(true);
		let filter = filtered.filter((filter) => filter.name !== data.name);
		setFiltered(filter);
		//Delete a status of array
		let arrStatusStr = String(status).split(",");
		let statusUrl = arrStatusStr.filter((element) => element !== data.value);
		setStatus(statusUrl);
		//Delete a delivery of array
		if (data.value === "ReciveHome" || data.value === "PickupStore") {
			var modalityOff = "";
			setIsDelivery("");
			setModality([]);
		}
		let returnPage = 1;
		setPage(returnPage);
		getOrdersList(
			sellerCode,
			returnPage,
			rowsPerPage,
			startDate,
			endDate,
			statusUrl,
			modalityOff === undefined ? isDelivery : modalityOff,
			searchData,
			dateType
		);
	};

	const getExportFilterOrders = async () => {
		setLoad(true);
		const params = {
			since: startDate,
			until: endDate,
			status: status,
			isDelivery: isDelivery,
			query: searchData,
			sellerCode: sellerCode,
			role: localStorage.getItem("role"),
		};
		await APIV1.get(`insights/export/orders?${buildURLWithParams(params)}`)
			.then((response) => {
				const exportFilter = setInterval(async () => {
					const resp = await axios.get(response.data.statusQueryGetUri);
					if (resp.data.runtimeStatus === "Running") {
					} else {
						switch (resp.data.runtimeStatus) {
							case "Completed":
								setOutput(resp.data.output);
								clearInterval(exportFilter);
								document.getElementById("exportList").click();
								setLoad(false);
								toast.success("Download realizado com sucesso!😃");
								break;
							case "Failed":
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
								break;
							default:
								clearInterval(exportFilter);
								toast.error("Não foi possível realizar a exportação !");
								setLoad(false);
						}
					}
				}, 4000);
			})
			.catch((error) => {
				console.log(error);
				toast.error(
					"Parece que tivemos um erro... Tente novamente mais tarde."
				);
			});
	};

	const skeleton = () => {
		return skele.map((index) => (
			<ContentLoader
				key={index}
				speed={5}
				width={1100}
				height={60}
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
				viewBox="0 0 1100 60"
				style={{ width: "100%" }}
			>
				<rect x="30" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="200" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="370" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="540" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="710" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="880" y="28" rx="3" ry="3" width="100" height="10" />
				<rect x="1000" y="28" rx="3" ry="3" width="20" height="10" />
			</ContentLoader>
		));
	};

	return (
		<div className="card">
			<div className="card-header d-flex justify-content-between align-items-center pt-3 pb-0">
				<h4 className="card-title">Pedidos</h4>
				{load ? (
					<>
						<a
							href={output}
							id="exportList"
							className="d-none"
							style={{ padding: "10px 20px" }}
						></a>
						<button
							disabled
							className="btn btn-outline btn-icon mx-1 d-flex justify-content-center"
							style={{
								padding: "10px",
								border: "1px solid #E9ECEC",
								background: "transparent",
								color: "#747E8B",
								width: "190px",
								height: "40px",
								cursor: "not-allowed",
							}}
						>
							<>
								<i className="fas fa-spinner fa-spin"></i>
								<span className="p-0 m-0" style={{ fontWeight: "500" }}>
									&nbsp;&nbsp;Processando...
								</span>
							</>
						</button>
					</>
				) : (
					<>
						<a
							href={output}
							id="exportList"
							className="d-none"
							style={{ padding: "10px 20px" }}
						></a>
						<button
							className="btn btn-outline btn-icon mx-1 d-flex justify-content-center"
							style={{
								padding: "10px",
								border: "1px solid #E9ECEC",
								background: "transparent",
								color: "#747E8B",
								width: "190px",
								height: "40px",
							}}
							onClick={() => getExportFilterOrders()}
						>
							<>
								<i className="material-icons">get_app</i>
								<span className="p-0 m-0" style={{ fontWeight: "400" }}>
									&nbsp;&nbsp;Exportar Pedidos
								</span>
							</>
						</button>
					</>
				)}
			</div>
			<OrderFiltersLayout
				isLoading={loading}
				orderFilters={{
					page: page,
					rowsPerPage: rowsPerPage,
					dateType: dateType,
					since: startDate,
					until: endDate,
					status: status,
					isDelivery: isDelivery,
					search: searchData,
					sellerCode: sellerCode,
				}}
				currentSeller={currentSeller}
				setCurrentSeller={setCurrentSeller}
				updateFilters={updateFilters}
				filtered={filtered}
				setFiltered={setFiltered}
				setModality={setModality}
				clearOneFilter={clearOneFilter}
				openModal={openModal}
			/>
			<div className="card-header d-flex flex-column py-0 px-4">
				<div className="d-flex align-items-center" style={{ width: "100%" }}>
					<strong>MOSTRANDO {loading ? "0" : maxCount} RESULTADOS</strong>
				</div>
				{!loading && (
					<FilterOrdersModal
						filterSelect={getFilterSelect}
						arrStatus={status}
						arrModality={modality}
					/>
				)}
			</div>
			<div className="card-content pt-4">
				<div className="card-body no-padding">
					{loading ? (
						skeleton()
					) : ordersList.length > 0 ? (
						<List
							data={ordersList}
							page={parseInt(page)}
							colums={OrderTableColumns()}
							overwriteCustomStyles={overwriteOrderStyles}
							conditionalRowStyles={conditionalRowStyles}
							totalRows={maxCount}
							handlePageChange={changePage}
							paginationServer={true}
							rowsPerPage={rowsPerPage}
							handleRowsPerPageChange={changeRowsPerPage}
							highlightOnHover={true}
							pointerOnHover={true}
							onRowClicked={(row) =>
								history.push(`/orders/${page}/detail/${row.id}`)
							}
						/>
					) : (
						<Empty img={"/images/empty_coupon.svg"} orders={true} />
					)}
				</div>
			</div>
		</div>
	);
}

import styled from "styled-components";
import {
	PaletteDiscriminatedUnion,
	paletteObject,
} from "../../theme/foundations/palette";

export const AlertContainer = styled.div<{
	color: PaletteDiscriminatedUnion;
	bgColor: PaletteDiscriminatedUnion;
}>`
	display: flex;
	direction: row;
	align-items: center;
	gap: 8px;
	width: 100%;
	padding: 8px 16px;
	border: 2px solid ${({ color }) => paletteObject[color].main};
	border-radius: 5px;
	color: ${({ color }) => paletteObject[color].main};
	background-color: ${({ bgColor }) => paletteObject[bgColor].main};
`;

import React, { useEffect, useState } from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import Axios from "axios";

// import { Container } from './styles';

export default function IntegrationState(props) {
	const [loading, setLoading] = useState(true);
	const [integrations, setIntegrations] = useState([]);

	useEffect(() => {
		getIntegrations();
		setInterval(async () => {
			getIntegrations();
		}, 60000);
	}, [props.reload]);

	const getIntegrations = () => {
		Axios.get(
			`${
				process.env.REACT_APP_APIV1_ENDPOINT
			}triggers/triggers?clientId=${localStorage.getItem("client_id")}`
		).then(async (response) => {
			setLoading(false);
			await setIntegrations(response.data);
		});
	};

	const cardTitle = (integration) => {
		switch (integration.action) {
			case "GetCategories":
				return "Categorias";
			case "GetProducts":
				return "Produtos";
			case "GetPrices":
				return "Preços";
			case "GetSkus":
				return "Variações";
			case "GetStocks":
				return "Estoque";
			default:
				break;
		}
	};

	const cardSubtitle = (integration) => {
		switch (integration.action) {
			case "GetCategories":
				return `${integration.executionCount} categorias integradas`;
			case "GetProducts":
				return `${integration.executionCount} produtos integrados`;
			case "GetPrices":
				return `${integration.executionCount} preços integrados`;
			case "GetSkus":
				return `${integration.executionCount} variações integradas`;
			case "GetStocks":
				return `${integration.executionCount} estoques integrados`;
			default:
				break;
		}
	};

	const status = (integration) => {
		if (integration.isRunning) {
			return "Em execução";
		} else {
			switch (integration.message) {
				case "Finalized":
					return "Sucesso";
				case "Running ":
					return "Em execução";
				default:
					return "Erro";
			}
		}
	};

	const icons = (integration) => {
		switch (integration.action) {
			case "GetCategories":
				return (
					<i
						className="material-icons"
						style={{ color: "#638EE7", fontSize: "24px" }}
					>
						list_alt
					</i>
				);
			case "GetProducts":
				return (
					<i
						className="fas fa-boxes"
						style={{ color: "#EF7B62", fontSize: "18px" }}
					></i>
				);
			case "GetPrices":
				return (
					<i
						className="material-icons"
						style={{ color: "#59CCDB", fontSize: "24px" }}
					>
						attach_money
					</i>
				);
			case "GetSkus":
				return (
					<i
						className="material-icons"
						style={{ color: "#638EE7", fontSize: "24px" }}
					>
						{" "}
						dynamic_feed
					</i>
				);
			case "GetStocks":
				return (
					<i
						className="fas fa-boxes"
						style={{ color: "#EF7B62", fontSize: "18px" }}
					></i>
				);
			default:
				break;
		}
	};

	const roundedStyle = (integration) => {
		switch (integration.action) {
			case "GetCategories":
				return { backgroundColor: "#E0E8FA", width: "40px", height: "40px" };
			case "GetProducts":
				return { backgroundColor: "#FCE3E0", width: "40px", height: "40px" };
			case "GetPrices":
				return { backgroundColor: "#DEF5F8", width: "40px", height: "40px" };
			case "GetSkus":
				return { backgroundColor: "#E0E8FA", width: "40px", height: "40px" };
			case "GetStocks":
				return { backgroundColor: "#FCE3E0", width: "40px", height: "40px" };
			default:
				break;
		}
	};

	const statusStyle = (integration) => {
		if (integration.isRunning) {
			return { fontSize: "14px", color: "#FDAC41" };
		} else {
			switch (integration.message) {
				case "Finalized":
					return { fontSize: "14px", color: "#01BFB5" };
				case "Running ":
					return { fontSize: "14px", color: "#FDAC41" };
				default:
					return { fontSize: "14px", color: "#FF5B5C" };
			}
		}
	};

	const dateHour = (integration) => {
		return moment(integration.timestamp).format("DD/MM/YYYY HH:mm");
	};

	const skeleton = () => {
		const skele = [0, 1, 2, 3];
		return skele.map(
			() => (
				// <div style={{overflow: 'hidden'}}>
				<ContentLoader
					speed={2}
					width={1100}
					height={70}
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
					viewBox="0 0 1100 100"
					style={{ width: "100%" }}
				>
					<rect x="150" y="20" rx="3" ry="3" width="200" height="20" />
					<rect x="150" y="51" rx="3" ry="3" width="80" height="6" />
					<rect x="900" y="20" rx="3" ry="3" width="1500" height="20" />
					<rect x="1040" y="51" rx="3" ry="3" width="59" height="8" />
					<circle cx="70" cy="51" r="50" />
				</ContentLoader>
			)
			// </div>
		);
	};

	const renderScreen = () => {
		return (
			<>
				{integrations.map(
					(integration, index) =>
						integration.isActive && (
							<div className="row mb-2" key={index}>
								<div className="col-12">
									<div className="row">
										<div className="col-2">
											<div
												className="rounded-circle d-flex align-items-center justify-content-center"
												style={roundedStyle(integration)}
											>
												{icons(integration)}
											</div>
										</div>
										<div className="col-6">
											<h2 className="card-title" style={{ fontSize: "14px" }}>
												{cardTitle(integration)}
											</h2>
											<h6
												className="card-subtitle mb-2 text-muted"
												style={{ fontSize: "12px" }}
											>
												{cardSubtitle(integration)}
											</h6>
										</div>
										<div className="col-4">
											<h2
												className="card-title text-right"
												style={statusStyle(integration)}
											>
												{status(integration)}
											</h2>
											<h6
												className="card-subtitle mb-2 text-muted text-right"
												style={{ fontSize: "12px" }}
											>{` ${dateHour(integration)}`}</h6>
										</div>
									</div>
								</div>
							</div>
						)
				)}
			</>
		);
	};

	const noIntegration = () => {
		return (
			<div className=" align-items-center py-5 empty">
				<div className="text-center">
					{/* <h3 style={{ color: '#4B5F79', fontSize: '24px', fontWeight: 'bold' }}>Ops, não temos nada por aqui ainda!</h3> */}
					<p style={{ color: "#C4C4C4", fontSize: "14px" }}>
						{" "}
						Seus status ainda não estão disponíveis no momento
					</p>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="card" style={{ height: "350px" }}>
				<div className="card-body">
					{localStorage.getItem("role") !== "1" && (
						<>
							<h2 className="card-title" style={{ fontSize: "18px" }}>
								Status das Integrações
							</h2>
							<h6
								className="card-subtitle mb-3 text-muted"
								style={{ fontSize: "14px" }}
							>
								Último Status de Integrações
							</h6>
						</>
					)}
					{loading
						? skeleton()
						: integrations.length > 0 && loading === false
						? renderScreen()
						: noIntegration()}
				</div>
			</div>
		</>
	);
}

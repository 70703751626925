import React from "react";
import { i18n } from "../../../translate/i18n";

export default function OrderHistoryStep({ historyStepObj, IsDelivery }) {
	const iconDict = {
		WaitingSeparation: "all_inbox",
		WaitingInvoice: "receipt_long",
		WaitingDispatch: "mail",
		WaitingDispatch_withdrawal: "card_giftcard",
		WaitingDelivery: "mark_email_read",
		WaitingDelivery_withdrawal: "shopping_bag",
		WaitingReturnGoods: "swap_horiz",
		ReturningGoods: "swap_horiz",
		WaitingCancel: "cancel",
		Canceled: "cancel",
	};

	const handleTimelineIcon = (stepObj) => {
		if (stepObj.hasOwnProperty("OperationId")) return;
		let stepId = stepObj?.OrderStatus;
		switch (stepObj?.status) {
			case "errorWarning":
				return "error_outline";
			case "canceled":
				return "cancel";
			case "processing":
				return "watch_later";
			case "retry":
				return "sync";
			case "completed":
				return "check_circle_outline";
			case "pending":
			default:
				return iconDict[stepId] || "check_circle_outline";
		}
	};

	const handleTitle = (stepObj, orderIsDelivery) => {
		if (stepObj.hasOwnProperty("OperationId")) {
			return stepObj.OperationId;
		}

		let stepId = stepObj?.OrderStatus;

		if (
			orderIsDelivery === false &&
			(stepId === "WaitingDispatch" || stepId === "WaitingDelivery")
		) {
			stepId = `${stepId}Withdrawal`;
		}

		if (stepId === "WaitingReturnGoods" || stepId === "WaitingCancel") {
			return i18n.t(`orderDetails.Status.${stepId}Period`);
		}

		if (stepObj?.status === "completed" || stepId === "Canceled") {
			return i18n.t(`orderDetails.Status.${stepId}Completed`);
		}

		if (stepObj?.status === "processing") {
			return i18n.t(`orderDetails.Status.${stepId}Processing`);
		}

		if (stepObj?.status === "retry") {
			return i18n.t(`orderDetails.Status.${stepId}Retry`);
		}

		if (stepObj?.status === "errorWarning") {
			return i18n.t(`orderDetails.Status.${stepId}ErrorWarning`);
		}

		if (stepObj?.status === "canceled") {
			return i18n.t(`orderDetails.Status.${stepId}Canceled`);
		}

		return i18n.t(`orderDetails.Status.${stepId}Pending`);
	};

	return (
		<section
			className={`order-history-step ${historyStepObj?.componentType} ${
				historyStepObj?.cancelIntegration
					? "cancel-integration"
					: historyStepObj.status
			} d-flex align-items-center`}
		>
			<span className="timeline-step-mark rounded-circle position-absolute d-flex align-items-center justify-content-center">
				{historyStepObj?.componentType === "OrderType" && (
					<i className="material-icons-outlined">
						{handleTimelineIcon(historyStepObj)}
					</i>
				)}
			</span>

			<i className="material-icons main-icon">{historyStepObj?.mainIcon}</i>

			{historyStepObj?.status !== "pending" && (
				<>
					<div className="flex-fill px-3">
						<h1 className="order-history-step-title py-0 my-0">
							{handleTitle(historyStepObj, IsDelivery)}
						</h1>

						{historyStepObj.componentType === "IntegrationType" && (
							<h1 className="order-history-step-text pt-1">
								<strong>Id da integração: </strong>{" "}
								{historyStepObj.IntegrationId}
							</h1>
						)}

						<span className="order-history-step-text">
							{historyStepObj?.issuer && `Via ${historyStepObj?.issuer} | `}
							{historyStepObj?.date}
						</span>
					</div>
					{historyStepObj?.urlLog && (
						<i
							className="material-icons-outlined downloadLog"
							onClick={() => (window.location.href = historyStepObj?.urlLog)}
						>
							download
						</i>
					)}
				</>
			)}

			{historyStepObj?.status === "pending" && (
				<>
					<h1 className="order-history-step-title flex-fill px-3 m-0">
						{handleTitle(historyStepObj, IsDelivery)}
					</h1>
					<span className="order-history-step-text">
						{i18n.t(`orderDetails.Status.NotStarted`)}
					</span>
				</>
			)}
		</section>
	);
}

import React, { useState, useEffect } from "react";
import { i18n } from "../../../../translate/i18n";
import { useOrders } from "../../providers/OrdersContext";
import { IconButton } from "@mui/material";
import CustomIcon from "../../../../MUICustomComponents/CustomIcon";
import { CloseRounded } from "@mui/icons-material";

function OrderFlowCancelModal({ position, isError }) {
	const { handleChangeStatus, order } = useOrders();
	const unparsedConfigurations = localStorage.getItem("configurations");
	const configurations =
		unparsedConfigurations && JSON.parse(unparsedConfigurations)[0];
	const enableChangeSeller = configurations?.enableChangeSeller;
	const [confirmStatus, setConfirmStatus] = useState(false);
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [approve, setApprove] = useState(false);
	const [enableInput, setEnableInput] = useState(false);
	const [reason, setReason] = useState("");
	const [cancellationType, setCancellationType] = useState(0);
	const [typeReason, setTypeReason] = useState([
		{ name: i18n.t("orderDetails.TypeReason.Reason1"), checked: false },
		{ name: i18n.t("orderDetails.TypeReason.Reason2"), checked: false },
		{ name: i18n.t("orderDetails.TypeReason.Reason3"), checked: false },
		{ name: i18n.t("orderDetails.TypeReason.Reason4"), checked: false },
		{ name: i18n.t("orderDetails.TypeReason.Reason5"), checked: false },
		{ name: i18n.t("orderDetails.TypeReason.Other"), checked: false },
	]);

	useEffect(() => {
		setApprove(confirmCancel && confirmStatus ? true : false);
	}, [confirmCancel, confirmStatus, reason]);

	const getCancellationType = (type) => {
		const cancellationTypeDict = {
			[i18n.t("orderDetails.TypeReason.Reason1")]: 1,
			[i18n.t("orderDetails.TypeReason.Reason3")]: 2,
			[i18n.t("orderDetails.TypeReason.Reason2")]: 3,
			[i18n.t("orderDetails.TypeReason.Reason4")]: 4,
			[i18n.t("orderDetails.TypeReason.Reason5")]: 5,
			[i18n.t("orderDetails.TypeReason.Other")]: 0,
		};
		return cancellationTypeDict[type] || 0;
	};

	const changeReason = (event) => {
		setCancellationType(0);
		setConfirmStatus(event.target.checked);

		setReason("");
		if (event.target.name === "Outro") {
			if (event.target.checked) {
				setEnableInput(true);
			} else {
				setEnableInput(false);
			}
		} else {
			setReason(event.target.name);
			setEnableInput(false);
		}
		let chosenReason = [];
		typeReason.forEach((reason) => {
			if (reason.name === event.target.name) {
				const body = { name: event.target.name, checked: event.target.checked };
				chosenReason.push(body);
			} else {
				const body = { name: reason.name, checked: false };
				chosenReason.push(body);
			}
		});
		setTypeReason(chosenReason);
		setCancellationType(getCancellationType(event.target.name));
	};

	const changeReasonInput = (event) => {
		setReason("");
		let reasonInput = event.target.value;
		reasonInput.length && setConfirmStatus(true);
		setReason(event.target.value);
	};

	const handleCancel = (tryToChangeSeller) => {
		if (!enableChangeSeller) {
			handleChangeStatus(position, isError, reason, cancellationType, null);
			return;
		}
		handleChangeStatus(
			position,
			isError,
			reason,
			cancellationType,
			tryToChangeSeller
		);
	};

	return (
		<div
			className="modal fade"
			id="orderFlowCancel"
			tabIndex="-1"
			aria-labelledby="orderFlowCancelLabel"
			aria-hidden="true"
		>
			<div
				className="modal-dialog modal-dialog-centered"
				style={{ minWidth: "567px" }}
			>
				<div
					className="modal-content"
					style={{ padding: "32px", minWidth: "567px ", borderRadius: "8px" }}
				>
					<div className="modal-header d-flex flex-column">
						<h5 className="modal-title mb-2">
							{i18n.t("orderDetails.OrderFlowCancelModal.TitleCancel")}
						</h5>
						<IconButton
							data-dismiss="modal"
							sx={{ position: "absolute", top: "16px", right: "16px" }}
						>
							<CustomIcon variant="default" icon={<CloseRounded />} />
						</IconButton>
						<p style={{ color: "#4B5F79", fontSize: "14px" }}>
							{i18n.t("orderDetails.OrderFlowCancelModal.TextAlertCancel")}
						</p>
						<div
							className="modal-body ml-0"
							style={{
								borderBottom: "1px solid #F5F5F5",
								paddingBottom: "20px",
							}}
						>
							<div className="custom-control custom-checkbox my-1 mr-sm-2">
								<input
									type="checkbox"
									checked={confirmCancel}
									className="custom-control-input"
									id="customControlInline20"
									onChange={(event) => setConfirmCancel(event.target.checked)}
								/>
								<label
									className="custom-control-label pl-2"
									htmlFor="customControlInline20"
									style={{ color: "#747E8B" }}
								>
									{i18n.t(
										"orderDetails.OrderFlowCancelModal.TextConfirmCancel"
									)}
								</label>
							</div>
						</div>
					</div>

					<div className="modal-header m-0 p-0 pb-2">
						<h5 className="modal-title" style={{ fontSize: "16px" }}>
							Razão para cancelamento
						</h5>
					</div>
					<div className="modal-body ml-0">
						{typeReason.map((data) => (
							<div key={data.id} className="custom-radio-input ml-0">
								<label className="radio-input pl-1">
									<input
										type="checkbox"
										name={data.name}
										checked={data.checked}
										id={`id${data.name}`}
										onChange={changeReason}
									/>
									<span className="checkmark"></span>
								</label>
								<label htmlFor={`id${data.name}`} id={`id${data.name}`}>
									{data.name}
								</label>
								<hr />
							</div>
						))}
						{enableInput && (
							<div className="count-input m-0 p-0">
								<input
									type="text"
									maxLength={20}
									className="form-control"
									placeholder="Clique para preencher"
									value={reason}
									onChange={changeReasonInput}
								/>
								<span>{`(${reason.length}/20)`}</span>
							</div>
						)}
					</div>
					<div className="modal-footer d-flex justify-content-end">
						{localStorage.getItem("role") !== "3" &&
							order.IsDelivery &&
							enableChangeSeller && (
								<button
									className={`btn ${
										approve && reason.length ? "btn-info" : "btn-light"
									} btn-icon d-flex justify-content-center`}
									data-dismiss="modal"
									disabled={approve && reason.length ? false : true}
									onClick={() => handleCancel(true)}
									style={{
										padding: "10px 20px",
										width: "50%",
										height: "40px",
									}}
								>
									{i18n.t("orderDetails.Status.ChangeSellerAction")}
								</button>
							)}
						<button
							className={`btn ${
								approve && reason.length ? "btn-danger" : "btn-light"
							} btn-icon d-flex justify-content-center`}
							data-dismiss="modal"
							disabled={approve && reason.length ? false : true}
							onClick={() =>
								handleCancel(
									localStorage.getItem("role") === "3" && order.IsDelivery
								)
							}
							style={{
								padding: "10px 20px",
								width: "50%",
								height: "40px",
							}}
						>
							{i18n.t("orderDetails.Status.CancelAction")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrderFlowCancelModal;

export const formatCurrency = (
	value: number | string,
	includeSymbol: boolean = true
): string => {
	const numberValue =
		typeof value === "string" ? parseFloat(value.replace(",", ".")) : value;

	const options: Intl.NumberFormatOptions = {
		...(includeSymbol && {
			style: "currency",
			currency: "BRL",
		}),
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	};
	const formatter = new Intl.NumberFormat("pt-BR", options);

	return formatter.format(numberValue);
};

export const formatCurrencyInputValue = (value: string) => {
	const formattedValue = value
		.replace(/\D/g, "")
		.replace(/(\d)(\d{2})$/, "$1,$2")
		.replace(/(?=(\d{3})+(\D))\B/g, ".");

	return formattedValue;
};

export function applyMask(value: string, mask: string): string {
	let maskedReturn = "";
	let valueIndex = 0;

	for (const maskChar of mask) {
		if (valueIndex >= value.length) break;

		if (maskChar === "9") {
			maskedReturn += value[valueIndex] || "";
			valueIndex++;
		} else {
			maskedReturn += maskChar;
		}
	}

	return maskedReturn;
}

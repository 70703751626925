import errorMessages from "./errors/errorMessagesPT.json";
import productManagement from "./productManagement/productManagementPT.json";
import exportScreen from "./exportScreen/exportScreenPT.json";
import header from "./header/headerPT.json";
import login from "./login/loginPT.json";
import products from "./products/productsPT.json";
import financial from "./financial/financialPT.json";
import forms from "./forms/formsPT.json";
import categories from "./categories/categoriesPT.json";
import dealers from "./dealers/dealersPT.json";
import attributes from "../pt-br/productAttributes/attributesPT.json";
import { ordersPT } from "./orders";
import { configurationsPT } from "./configurations";
import { enums } from "./enums";
import { componentsPT } from "./components";
import { cpOrdersPT } from "./ordersCP";
import { dashboardPT } from "./dashboard";
import { storesPT } from "./stores";

const messages = {
	pt: {
		translations: {
			errorMessages,
			productManagement,
			exportScreen,
			header,
			login,
			products,
			financial,
			forms,
			categories,
			dealers,
			attributes,
			...ordersPT,
			...configurationsPT,
			...enums,
			...componentsPT,
			...cpOrdersPT,
			...dashboardPT,
			...storesPT,
		},
	},
};

export { messages };

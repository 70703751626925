import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { GetDomain, GetLegacyDomain } from "./ApiHelper";
import { HttpMethod } from "../../domain/enums/HttpMethodType";

export default abstract class BaseApi {
	constructor(apiAddress: string, isLegacyApi: boolean = false) {
		this.baseUrl = (isLegacyApi ? GetLegacyDomain() : GetDomain()) + apiAddress;
	}

	private readonly baseUrl: string;

	protected isResponseSuccessful(statusCode: number) {
		if ([200, 201, 202, 204, 206].includes(statusCode)) return true;

		return false;
	}

	protected async request<T>(
		uri: string,
		method: HttpMethod,
		body: object | null = null
	): Promise<AxiosResponse<T>> {
		let req: AxiosRequestConfig = {
			baseURL: this.baseUrl,
			url: uri,
			method: method,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`, // Hardcoded, can change later
				"Content-Type": "application/json", // Hardcoded, can change later
			},
			data: body === null ? null : JSON.stringify(body), // TODO, test if this works
		};

		let resp: AxiosResponse<T>;
		try {
			resp = await Axios.request<T>(req);
		} catch (err: unknown) {
			// Needs to be unknown because typescript
			// doesn't know the possible exceptions at runtime,
			// and neither do we
			let axiosError = err as AxiosError<T>;
			if (
				axiosError?.isAxiosError &&
				axiosError?.response !== null &&
				axiosError?.response !== undefined
			) {
				resp = axiosError.response;
				return resp;
			}

			// Rethrow error up the stack, we don't want to catch here
			// because there are no errors expected besides AxiosError
			throw err;
		}

		return resp;
	}
}
